import { Injectable } from '@angular/core';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { ModalService } from '@rocketcoms/rocket-ui';
import { Observable, from, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ConfirmModalComponent } from '@rocketcoms/components';
import { fromAuth, fromRoot } from '../..';

export interface CanComponentDeactivate {
  canDeactivate: () => boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateGuard {
  isAuthenticated$ = this.store.select(fromAuth.selectLoggedIn);

  constructor(
    private modalService: ModalService,
    private store: Store,
  ) {}

  canDeactivate(component: CanComponentDeactivate): Observable<boolean> {
    return this.isAuthenticated$.pipe(
      concatLatestFrom(() => [this.store.select(fromRoot.selectUrl)]),
      switchMap(([isAuthenticated, currentUrl]) => {
        if (!isAuthenticated) {
          return of(true);
        } else {
          const deactivateOrShowDialog = component.canDeactivate()
            ? of(true)
            : from(this.openConfirmDialog({ isMessageFlowDetails: currentUrl?.split('/')[1] === 'message-flows' }));
          return component.canDeactivate !== undefined ? deactivateOrShowDialog : of(true);
        }
      }),
    );
  }

  openConfirmDialog({ isMessageFlowDetails }: { isMessageFlowDetails?: boolean }): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
      className: 'confirm-modal',
    });

    if (isMessageFlowDetails) {
      modalRef.componentInstance.title = 'Leave Message Flow';
      modalRef.componentInstance.text =
        'Are you sure you want to leave editing the Message Flow? Your progress will not be saved. This action cannot be undone.';
    } else {
      modalRef.componentInstance.title = 'Unsaved changes';
      modalRef.componentInstance.text =
        'You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.';
    }
    return modalRef.result.then(
      (result) => !!result,
      () => false,
    );
  }
}
