import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions, FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'rkt-form',
  templateUrl: './form.component.html',
  standalone: true,
  imports: [FormlyModule],
})
export class RktFormComponent {
  @HostBinding('class.rkt-form') commonClass = true;

  @Input() public form = new FormGroup({});

  @Input() public fields: FormlyFieldConfig[] = [];

  @Input() public model: Record<string, unknown> = {};

  @Input() public options: FormlyFormOptions = {};

  @Output() public modelChange = new EventEmitter<unknown>();
}
