import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-text-with-placeholder',
  templateUrl: 'text-with-placeholder.component.html',
  styleUrls: ['text-with-placeholder.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor],
})
export class TextWithPlaceholderComponent {
  @Input() width = '8.75';

  @Input() height = '1';

  @Input() showPlaceholder = true;

  @Input() placeholderRows = 1;

  get rows() {
    return new Array(this.placeholderRows);
  }
}
