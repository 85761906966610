import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { UserCredentials } from '@rocketcoms/interfaces';

export const LoginPageActions = createActionGroup({
  source: 'Login Page Actions',
  events: {
    initialize: emptyProps,
    login: props<{ userCredentials: UserCredentials }>(),
    resetLoginError: emptyProps,
    loggedInRedirect: emptyProps,
  },
});
