import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RktButtonDirective, ActiveModal } from '@rocketcoms/rocket-ui';
import { Subject, filter, fromEvent, takeUntil } from 'rxjs';

import { AttentionModalComponent } from '../attention-modal/attention-modal.component';

@Component({
  selector: 'app-error-modal',
  templateUrl: 'error-modal.component.html',
  styleUrls: ['error-modal.component.scss'],
  standalone: true,
  imports: [AttentionModalComponent, RktButtonDirective],
})
export class ErrorModalComponent implements OnInit, OnDestroy {
  @Input() errorMessage = 'We are unable to complete this request. Please contact your administrator.';

  errorType?: string | number;

  modalTitle!: string;

  buttonText = 'Continue';

  private destroy$ = new Subject<void>();

  constructor(public activeModal: ActiveModal) {
    fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(
        filter((e: KeyboardEvent) => {
          return e.key === 'Enter';
        }),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: () =>
          requestAnimationFrame(() => {
            this.activeModal.close();
          }),
      });
  }

  ngOnInit() {
    this.modalTitle = this.errorType === 403 ? 'Permission denied' : 'Error';
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
