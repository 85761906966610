import { Component, EventEmitter, Input, Output } from '@angular/core';

import { RktIconComponent } from '../../icon';
import { NgClass, NgStyle, NgIf } from '@angular/common';
import { DatatableColumn, IconSet } from '@rocketcoms/interfaces';

@Component({
  selector: 'rkt-table-header-cell',
  templateUrl: './header-cell.component.html',
  standalone: true,
  imports: [NgClass, NgStyle, NgIf, RktIconComponent],
})
export class RktTableHeaderCellComponent {
  @Output() sortEmitter: EventEmitter<void> = new EventEmitter();

  @Input() headerHeight!: number;

  @Input() iconSet!: IconSet;

  @Input() column!: DatatableColumn;

  @Input() isSortable?: boolean = false;

  sortDir?: 'asc' | 'desc';

  @Input()
  set sortDirection(sortDir: 'asc' | 'desc') {
    this.sortDir = sortDir;
  }

  sortColumn(): void {
    if (this.isSortable) {
      this.sortEmitter.emit();
    }
  }
}
