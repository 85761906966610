import { Injectable, Injector } from '@angular/core';

export type ModalOption = 'beforeDismiss' | 'container' | 'injector' | 'keyboard' | 'size';

export interface ModalOptions {
  beforeDismiss?: () => boolean | Promise<boolean>;
  container?: string;
  injector?: Injector;
  keyboard?: boolean;
  size?: 'sm' | 'lg' | 'md' | 'full-size' | 'half-size';
  className?: string;
  isDraggable?: boolean;
}

@Injectable({ providedIn: 'root' })
export class ModalConfig implements Required<ModalOptions> {
  beforeDismiss!: () => boolean | Promise<boolean>;
  container!: string;
  injector!: Injector;
  keyboard = true;
  size!: 'sm' | 'lg' | 'md' | 'full-size' | 'half-size';
  className!: string;
  isDraggable = false;
}
