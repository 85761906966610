<div class="modal-overlay modal app-modal" [ngClass]="className" [class.is-draggable]="isDraggable" #ModalDialog>
  <div
    class="modal-dialog modal-dialog-centered {{ sizeClasses }}"
    [rktModalDraggable]="isDraggable"
    [handle]="isDraggable ? dragHandle : undefined"
    [bounds]="isDraggable ? ModalDialog : undefined"
  >
    <div class="modal-content">
      <div class="modal-body">
        <div class="modal-drag-handle" [class.hidden]="!isDraggable" #dragHandle></div>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>

<div class="modal-backdrop show"></div>
