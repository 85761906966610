<div class="password-strength" [class.modal-form]="isModalForm">
  <div class="password-strength-title">Password requirements</div>
  <div class="password-strength-container">
    <div class="password-strength-item" [class.active]="containAtLeastEightChars">
      <span class="password-strength-icon">
        <rkt-icon name="check"></rkt-icon>
      </span>
      Minimum 8 characters
    </div>

    <div class="password-strength-item" [class.active]="containAtLeastOneLowerCaseLetter">
      <span class="password-strength-icon">
        <rkt-icon name="check"></rkt-icon>
      </span>
      At least one lowercase letter
    </div>

    <div class="password-strength-item" [class.active]="containAtLeastOneUpperCaseLetter">
      <span class="password-strength-icon">
        <rkt-icon name="check"></rkt-icon>
      </span>
      At least one uppercase letter
    </div>

    <div class="password-strength-item" [class.active]="containAtLeastOneDigit">
      <span class="password-strength-icon">
        <rkt-icon name="check"></rkt-icon>
      </span>
      At least one number
    </div>

    <div class="password-strength-item" [class.active]="containAtLeastOneSpecialChar">
      <span class="password-strength-icon">
        <rkt-icon name="check"></rkt-icon>
      </span>
      At least one special case
    </div>
  </div>
</div>
