<div class="wrapper" [class.main-nav-expanded]="isMenuExpanded$ | async">
  <button class="nav-toggle-btn" *ngIf="(isMenuExpanded$ | async) === false" (click)="onExpandNavBtnClick()"></button>

  <button class="nav-toggle-btn nav-toggle-btn-collapse" *ngIf="isMenuExpanded$ | async" (click)="onCollapseNavBtnClick()"></button>

  <div class="main-nav-container">
    <app-main-nav></app-main-nav>
  </div>

  <div class="content-container">
    <router-outlet></router-outlet>
  </div>
</div>
