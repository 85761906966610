import { FormlyFieldConfig } from '@ngx-formly/core';

export const formRow = (fieldConfig: FormlyFieldConfig[], config: { className?: string; isHidden?: boolean } = {}) => {
  const defaultClassName = 'rkt-form-row';

  return {
    fieldGroupClassName: config.className ? `${defaultClassName} ${config.className}` : defaultClassName,
    fieldGroup: fieldConfig,
    className: ['rkt-form-row-group', config.isHidden ? 'hidden' : ''].join(' '),
  };
};
