import { FormlyFieldConfig } from '@ngx-formly/core';
import { FieldHelperProps, InputPhoneProps } from '../form.types';

export const formInputPhone = (config: FieldHelperProps<InputPhoneProps>): FormlyFieldConfig => {
  const defaultClassName = 'rkt-form-control';
  return {
    key: config.key,
    name: config.name,
    type: 'input-phone',
    className: config.className ? `${defaultClassName} ${config.className}` : defaultClassName,
    defaultValue: config.defaultValue,
    props: {
      label: config.label,
      ...config.props,
    },
    validators: { ...config.validators, validation: [{ name: 'isValidPhoneNumber' }] },
    validation: { ...config.validation },
    expressions: { ...config.expressions },
  };
};
