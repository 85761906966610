import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthActions, fromAuth } from '../../..';
import { RktIconComponent, MenuItem, RktMenuComponent } from '@rocketcoms/rocket-ui';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
  standalone: true,
  imports: [RouterLinkActive, RouterLink, RktIconComponent, NgIf, RktMenuComponent, AsyncPipe, NgFor],
})
export class MainNavComponent {
  isMenuExpanded$ = this.store.select(fromAuth.selectMenuExpanded);

  activeUser$ = this.store.select(fromAuth.selectUser);

  dropdownMenuItems: MenuItem[];

  menuItems = [
    {
      link: '/business-accounts',
      icon: 'company',
      text: 'Business Accounts',
    },
    {
      link: '/campaigns',
      icon: 'campaign',
      text: 'Campaigns',
    },
    {
      link: '/users',
      icon: 'team',
      text: 'Users',
    },
    {
      link: '/filters',
      icon: 'filters',
      text: 'System Filters',
    },
  ];

  constructor(
    private store: Store,
    private router: Router,
  ) {
    this.dropdownMenuItems = [
      {
        label: 'Account Details',
        type: 'link',
        command: () => {
          this.router.navigate(['settings']);
        },
      },
      {
        label: 'Log out',
        iconName: 'logout',
        type: 'link',
        command: () => {
          this.store.dispatch(AuthActions.logout({}));
        },
      },
    ];
  }
}
