<div
  [ngClass]="{
    'input-switch': true,
    'input-switch-checked': checked,
    'input-switch-disabled': disabled,
    'input-switch-focused': focused,
    'input-switch-default-theme': !isCustomTheme,
  }"
  [ngStyle]="style"
  [class]="styleClass"
  (click)="onClick($event)"
>
  <div class="input-switch-accessible">
    <input
      #rbnkInputSwitch
      type="checkbox"
      [attr.id]="inputId"
      [attr.name]="name"
      [attr.tabindex]="tabindex"
      [checked]="checked"
      (change)="onInputChange($event)"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [disabled]="disabled"
      role="switch"
      [attr.aria-checked]="checked"
      [attr.aria-labelledby]="ariaLabelledBy"
    />
  </div>
  <span class="input-switch-slider"></span>
</div>
