import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of, timer } from 'rxjs';
import { catchError, concatMap, delayWhen, exhaustMap, map, switchMap, tap } from 'rxjs/operators';

import { LocalStorageService } from '@rocketcoms/data-access';
import { AuthActions } from './auth.actions';
import { LoginPageActions } from './login-page.actions';
import { LoginService, MessagesActions, ModalsActions, fromRoot, fromAuth } from '../../..';

@Injectable()
export class AuthEffects implements OnInitEffects {
  constructor(
    private actions$: Actions,
    private loginService: LoginService,
    private router: Router,
    private store: Store,
    private storageService: LocalStorageService,
  ) {}

  ngrxOnInitEffects() {
    return AuthActions.rehydrateState();
  }

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginPageActions.login),
      map((action) => action.userCredentials),
      exhaustMap(({ email, password }) =>
        this.loginService.login(email, password).pipe(
          map((data) => {
            const { user, refreshToken, token, expiry } = data.result;
            if (user.role.toLowerCase() !== 'admin') {
              return AuthActions.loginForbidden();
            }
            return AuthActions.loginSuccess({
              user: user,
              tokenInfo: { refreshToken, token, expiry },
              brand: null,
            });
          }),
          catchError((error) => {
            return of(AuthActions.loginFailure({ error: error.message }));
          }),
        ),
      ),
    );
  });

  loginPerformed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginPageActions.login),
      switchMap(() => of(MessagesActions.clearAll())),
    );
  });

  loginSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        concatLatestFrom(() => [this.store.select(fromRoot.selectQueryParams)]),
        tap({
          next: ([, queryParams]) => {
            this.router.navigateByUrl(queryParams?.['returnUrl'] || '/business-accounts');
          },
        }),
      );
    },
    { dispatch: false },
  );

  loggedInRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LoginPageActions.loggedInRedirect),
        tap({
          next: () => {
            this.router.navigateByUrl('/');
          },
        }),
      );
    },
    { dispatch: false },
  );

  loginForbidden$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loginForbidden),
      map(() =>
        ModalsActions.openErrorDialog({
          title: 'Permission denied',
          text: 'You do not have permission to access. Please contact Administrator.',
        }),
      ),
    );
  });

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.logout),
      exhaustMap(({ params }) => {
        return this.loginService.logout().pipe(map(() => AuthActions.logoutCompleted({ params })));
      }),
    );
  });

  logoutCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.logoutCompleted),
      tap({
        next: ({ params }) => {
          const { skipNavigation = false, isReturnUrl } = params ?? {};
          const returnUrl = this.router.url;

          if (!skipNavigation) {
            const routerQueryParams =
              isReturnUrl && !['/dashboard', '404', '/'].includes(returnUrl) ? { queryParams: { returnUrl } } : undefined;
            // TODO logout redirect issue
            this.router.navigateByUrl('/auth', { replaceUrl: true, ...routerQueryParams });
          }

          this.storageService.clear();
        },
      }),
      map(() => ModalsActions.dismissAll()),
    );
  });

  refreshToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.refreshToken, AuthActions.rehydrateState),
      concatLatestFrom(() => [this.store.select(fromAuth.selectLoggedIn), this.store.select(fromAuth.selectTokenInfo)]),
      concatMap(([, isLoggedIn, tokenInfo]) => {
        if (isLoggedIn && tokenInfo) {
          return this.loginService.refresh(tokenInfo.refreshToken).pipe(
            map((refreshToken) => {
              return AuthActions.refreshTokenSuccess({ tokenInfo: refreshToken });
            }),
            catchError(() => of(AuthActions.logout({ params: { isReturnUrl: true } }))),
          );
        } else {
          return of(AuthActions.noActiveSession());
        }
      }),
    );
  });

  refreshTokenSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.refreshTokenSuccess),
      concatLatestFrom(() => this.store.select(fromAuth.selectTokenExpireInMilliseconds)),
      delayWhen(([, expiresIn]) => timer(expiresIn * 0.9)),
      map(() => AuthActions.refreshToken()),
    );
  });
}
