<div
  class="header-cell"
  (click)="sortColumn()"
  [class.header-cell-sortable]="isSortable"
  [ngClass]="[
    column.prop ? 'header-cell-' + column.prop : '',
    column.className ? 'header-cell-' + column.className : '',
    'header-cell-align-' + (column.colAlign || 'left'),
  ]"
  [ngStyle]="{ 'height.px': headerHeight }"
>
  <div class="header-cell-inner">
    <div class="header-cell-value" [title]="column.name">{{ column.name }}</div>
    <span class="column-sort-btn" *ngIf="isSortable">
      <rkt-icon name="arrow-down" *ngIf="sortDir === 'desc'" class="sort-icon"></rkt-icon>

      <rkt-icon name="arrow-up" *ngIf="sortDir === 'asc'" class="sort-icon"></rkt-icon>

      <rkt-icon name="arrow-down" *ngIf="!sortDir || !sortDir.length" class="sort-icon inactive"></rkt-icon>
    </span>
  </div>
</div>
