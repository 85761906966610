import parsePhoneNumber, { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import { isNil, isString } from 'lodash-es';

export const toPhone = (val?: string, region: CountryCode = 'US'): string => {
  if (!isString(val) || isNil(val)) {
    return '';
  }

  const phoneNumber = parsePhoneNumber(val, region);

  if (!isValidPhoneNumber(val, region) || !phoneNumber) {
    if (val.length === 11) {
      const match = val.match(/^(1)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        const intlCode = match[1] ? '+1 ' : '';
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
      }
    }

    return val;
  }

  return `+${phoneNumber.countryCallingCode} ${phoneNumber.formatNational()}`;
};
