import { HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { toPhone } from '@rocketcoms/utils';

import { PageParamsRequestProps, UserList, UserListResponse, User, UserRaw } from '@rocketcoms/interfaces';
import { ApiService } from '@rocketcoms/data-access';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  apiService = inject(ApiService);

  public getUsers({ page, sortParams }: PageParamsRequestProps): Observable<UserList> {
    const pageSize = 15;

    let params = new HttpParams().set('page', `${page}`).set('pageSize', `${pageSize}`);

    if (sortParams) {
      params = params.append('sortBy', sortParams.key);
      params = params.append('sortDir', sortParams.sortDir);
    }

    return this.apiService
      .get<UserListResponse>('/admin/users', {
        params,
      })
      .pipe(
        map((response) => this.mapUserListData(response)),
        shareReplay(),
      );
  }

  private mapUserListData(response: UserListResponse): UserList {
    const { content, total } = response.result;

    const items = content.map((item) => this.mapUserData(item));

    return {
      items,
      totalElements: total,
    };
  }

  public addAdminUser(user: any): Observable<User> {
    return this.apiService.post<User>('/admin/users', user).pipe(map((response) => response));
  }

  private mapUserData(user: UserRaw): User {
    return {
      ...user,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      mobileNumber: toPhone(user.mobileNumber),
      address: user.address
        ? {
            addressType: user.address.addressType || undefined,
            streetLine1: user.address.streetLine1 || undefined,
            countryCode: user.address.countryCode || undefined,
            cityName: user.address.cityName || undefined,
            stateCode: user.address.stateCode || undefined,
            zipCode: user.address.zipCode || undefined,
          }
        : undefined,
      businessAccount: user.businessAccount
        ? {
            id: user.businessAccount.id,
            legalName: user.businessAccount.legalName,
            website: user.businessAccount.website,
          }
        : undefined,
      role: user.role,
      title: user.title,
      resendVerificationLinkBtn: 'resendVerificationLinkBtn',
    };
  }

  resendVerificationLink(id: string) {
    return this.apiService.post(`/admin/users/${id}/reset-password`);
  }
}
