import { AbstractControl, ValidationErrors } from '@angular/forms';
import { countries } from '../utils';

export const countryCodeValidator = (control: AbstractControl): ValidationErrors | null => {
  if (!control.value) {
    return null;
  }

  return (Object.keys(countries) as Array<string>).filter((key) => countries[key].iso3 === control.value.toUpperCase()).length
    ? null
    : { isValidCountryCode: { message: `Please select a valid country code.` } };
};
