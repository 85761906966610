import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONFIG } from '@rocketcoms/app-config';

export const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  config = inject(APP_CONFIG) as { apiBaseUrl: string };

  get baseUrl(): string {
    return this.config.apiBaseUrl;
  }

  constructor(private http: HttpClient) {}

  get<T>(path: string, options: object = {}): Observable<T> {
    return this.http.get<T>(this.baseUrl + path, options);
  }

  getFile<T>(path: string, options: object = {}): Observable<T> {
    return this.http.get<T>(path, options);
  }

  put<T>(path: string, body: object = {}): Observable<T> {
    return this.http.put<T>(this.baseUrl + path, body, httpOptions);
  }

  patch<T>(path: string, body: object = {}): Observable<T> {
    return this.http.patch<T>(this.baseUrl + path, body, httpOptions);
  }

  post<T>(path: string, body: object = {}): Observable<T> {
    return this.http.post<T>(this.baseUrl + path, body, httpOptions);
  }

  delete<T>(path: string): Observable<T> {
    return this.http.delete<T>(this.baseUrl + path, httpOptions);
  }

  upload<T>(path: string, body: object = {}): Observable<T> {
    return this.http.post<T>(this.baseUrl + path, body);
  }

  update<T>(path: string, body: object = {}): Observable<T> {
    return this.http.patch<T>(this.baseUrl + path, body);
  }
}
