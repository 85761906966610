<pagination-template
  #pager="paginationApi"
  [id]="id"
  [maxSize]="
    pager.getTotalItems() && pager.getTotalItems() / limit <= 10
      ? 10
      : maxSize + (pager.getCurrent() >= 5 && pager.getCurrent() < pager.getTotalItems() / limit - 3 ? 2 : 0)
  "
  (pageChange)="pageChange.emit($event)"
  (pageBoundsCorrection)="pageBoundsCorrection.emit($event)"
>
  <div class="page-list" *ngIf="!(autoHide && pager.pages.length <= 1)">
    <div class="pager-label" *ngIf="paginationLabel">{{ paginationLabel }}</div>
    <div class="page-item page-item-arrow-prev">
      <button class="page-button" (click)="pager.previous()" [disabled]="pager.isFirstPage()" [class.disabled]="pager.isFirstPage()">
        <rkt-icon name="chevron-left"></rkt-icon>
      </button>
    </div>

    <div
      class="page-item"
      [ngClass]="'page-num-' + page.value"
      [class.current]="pager.getCurrent() === page.value"
      [class.ellipsis]="page.label === '...'"
      *ngFor="let page of pager.pages; trackBy: trackByIndex"
    >
      <button class="page-button" [class.active]="pager.getCurrent() === page.value" (click)="pager.setCurrent(page.value)">
        <div class="button-text">{{ page.label }}</div>

        <rkt-icon class="arrow-icon" *ngIf="page.label === '...' && page.value > pager.getCurrent()" name="double-arrow-right"></rkt-icon>

        <rkt-icon class="arrow-icon" *ngIf="page.label === '...' && page.value < pager.getCurrent()" name="double-arrow-left"></rkt-icon>
      </button>
    </div>

    <div class="page-item page-item-arrow-next">
      <button class="page-button" (click)="pager.next()" [disabled]="pager.isLastPage()" [class.disabled]="pager.isLastPage()">
        <rkt-icon name="chevron-right"></rkt-icon>
      </button>
    </div>
  </div>
</pagination-template>
