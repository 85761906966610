<div class="details-item-content">
  <div class="details-item-label">
    {{ label }}
    @if (tooltip) {
      <div class="tooltip-container" (mouseover)="showTooltip()" (mouseout)="hideTooltip()">
        <rkt-icon class="details-item-tooltip" name="info"></rkt-icon>
        <div class="tooltip-message" [hidden]="!isTooltipDisplayed">{{ tooltip }}</div>
      </div>
    }
  </div>

  <app-text-with-placeholder
    class="details-item-value"
    [width]="placeholderWidth"
    [height]="height"
    [showPlaceholder]="showPlaceholder"
    [class.not-available]="!value"
    [class.no-break]="noBreak"
    [class.loading]="showPlaceholder"
    [placeholderRows]="placeholderRows"
    [style.minHeight.rem]="minHeight"
    [title]="value | notAvailable"
    >{{ templateValueOnly ? '' : (value | notAvailable) }}<ng-content></ng-content
  ></app-text-with-placeholder>
</div>
