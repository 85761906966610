import { Component, HostBinding } from '@angular/core';
import { FieldArrayType, FormlyModule } from '@ngx-formly/core';
import { RktIconComponent } from '../../icon';
import { NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'rkt-form-repeat-section',
  template: `
    <div *ngFor="let field of field.fieldGroup; let i = index; last as isLast" class="repeat-section-item-wrapper">
      <div class="repeat-section-header" *ngIf="header">{{ header }}</div>

      <div class="repeat-section-subheader" *ngIf="subheader">{{ subheader }}</div>

      <div class="rkt-form-repeat-section-item">
        <formly-field class="rkt-form-row-group" [field]="field"></formly-field>
        <button class="repeat-section-remove-btn" type="button" *ngIf="notAllowEmpty ? i !== 0 : true" (click)="onRemove(i)">
          <rkt-icon name="trash" class="remove-icon"></rkt-icon>
          {{ removeText }}
        </button>
      </div>
      <div>
        <div *ngFor="let button of extraButtons; let j = index" class="repeat-section-button-container">
          <ng-container *ngIf="!(button.skipFirstSection && i === 0)">
            <button *ngIf="button.type === 'button'" type="button" (click)="button.onClick(i)" class="repeat-section-btn">
              <rkt-icon *ngIf="button.icon" [name]="button.icon" class="repeat-section-button-icon"></rkt-icon> {{ button.label }}
            </button>

            <div class="rkt-form-radio repeat-section-btn-radio">
              <label *ngIf="button.type === 'radio'" class="rkt-form-radio-label" (click)="button.onClick(i)">
                <div class="rkt-form-radio-icon"></div>
                <div class="rkt-form-radio-value">
                  {{ button.label }}
                </div>
              </label>
            </div>
          </ng-container>
          <button *ngIf="isAddVisible && isLast" type="button" (click)="add()" class="repeat-section-add-btn">
            <rkt-icon name="plus" class="add-icon"></rkt-icon> {{ addText }}
          </button>
        </div>
      </div>
    </div>
    <button *ngIf="isAddVisible && !extraButtons.length" type="button" (click)="add()" class="repeat-section-add-btn">
      <rkt-icon name="plus" class="add-icon"></rkt-icon> {{ addText }}
    </button>
  `,
  standalone: true,
  imports: [NgFor, NgIf, FormlyModule, RktIconComponent],
})
export class RktFormRepeatSectionComponent extends FieldArrayType {
  @HostBinding('class.rkt-form-repeat-section') commonClass = true;

  @HostBinding('class.unstyled') get unstyledClass(): boolean {
    return !!this.props['unstyled'];
  }

  get addText() {
    return this.props['addText'] || 'Add';
  }

  get header() {
    return this.props['header'];
  }

  get subheader() {
    return this.props['subheader'];
  }

  get removeText() {
    return this.props['removeText'] || '';
  }

  get notAllowEmpty() {
    return this.props['notAllowEmpty'];
  }

  get isAddVisible() {
    const maxItems = this.props['maxItems'];
    if (!maxItems) return true;

    return this.field.fieldGroup!.length < maxItems;
  }

  get extraButtons() {
    return this.props['extraButtons'] || [];
  }

  onRemove(i: number) {
    if (this.props['onRemove']) {
      this.props['onRemove'](i, (index: number) => this.remove(index));
    } else {
      this.remove(i);
    }
  }
}
