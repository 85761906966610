import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class LegalService {
  constructor(private apiService: ApiService) {}

  downloadPrivacyPolicy(): void {
    const url = '/files/RocketFNCL_Privacy_Policy_220427.pdf';
    this.apiService.getFile<ArrayBuffer>(url, { responseType: 'blob' }).subscribe({
      next: (response) => {
        this.downloadFile(response, 'RocketFNCL_Privacy_Policy_220427.pdf');
      },
    });
  }

  downloadTermsConditions(): void {
    const url = '/files/RocketFNCL_Terms_and_Conditions_220427.pdf';
    this.apiService.getFile<ArrayBuffer>(url, { responseType: 'blob' }).subscribe({
      next: (response) => {
        this.downloadFile(response, 'RocketFNCL_Terms_and_Conditions_220427.pdf');
      },
    });
  }

  private downloadFile(response: ArrayBuffer, fileName = 'batch') {
    const blob = new Blob([response], { type: 'text/csv' });
    const file = new File([blob], fileName);
    saveAs(file);
  }
}
