import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const MessagesActions = createActionGroup({
  source: 'Message Actions',
  events: {
    displayError: props<{ message: string }>(),
    displaySuccess: props<{ message: string }>(),
    displayWarning: props<{ message: string }>(),
    clearAll: emptyProps,
  },
});
