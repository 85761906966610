import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { SelectCountryProps } from '../form.types';
import { getCountryFlagByAlpha3, getCountryListSelectOptions } from '../utils';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
  selector: 'rkt-form-select-country',
  template: `
    <ng-select
      #selectCountry
      class="rkt-form-select"
      [class.is-invalid]="showError"
      [items]="countryList | formlySelectOptions: field | async"
      [placeholder]="to.placeholder || ''"
      [tabIndex]="to.tabindex || 0"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [bindLabel]="labelProp"
      [bindValue]="valueProp"
      [clearable]="false"
      [searchable]="true"
      [virtualScroll]="true"
      (close)="selectCountry.blur()"
      [selectOnTab]="false"
      [closeOnSelect]="true"
      notFoundText="Country not found"
    >
      <ng-template ng-label-tmp let-item="item">
        <div class="ng-value-label ng-country-value-label">
          <span class="option-icon fi" *ngIf="item.value" [ngClass]="'fi-' + getCountryFlag(item.value)"></span>
          <span class="option-value">{{ item['label'] }}</span>
        </div>
      </ng-template>

      <ng-template ng-option-tmp let-item="item">
        <div class="ng-option-label ng-country-value-label">
          <span class="option-icon fi" *ngIf="item.value" [ngClass]="'fi-' + getCountryFlag(item.value)"></span>
          <span class="option-value">{{ item['label'] }}</span>
        </div>
      </ng-template>
    </ng-select>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgSelectModule, ReactiveFormsModule, FormlyModule, NgIf, NgClass, AsyncPipe, FormlySelectModule],
})
export class RktFormSelectCountryComponent extends FieldType<FieldTypeConfig<SelectCountryProps>> implements OnInit {
  @HostBinding('class.rkt-form-select-field') commonClass = true;

  @HostBinding('class.rkt-form-select-country-field') countrySelectClass = true;

  get labelProp(): string {
    return this.props.labelProp || 'label';
  }

  get valueProp(): string {
    return this.props.valueProp || 'value';
  }

  getCountryFlag(value?: string): string | undefined {
    return getCountryFlagByAlpha3(value);
  }

  countryList: { label: string; value: string }[] = [];

  ngOnInit(): void {
    this.countryList = getCountryListSelectOptions(this.props?.countryRestrictions);
  }
}
