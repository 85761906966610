export interface IPosition {
  x: number;
  y: number;
}

export class Position implements IPosition {
  constructor(
    public x: number,
    public y: number,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromEvent(e: MouseEvent | TouchEvent, el: any = null) {
    if (this.isMouseEvent(e)) {
      return new Position(e.clientX, e.clientY);
    } else {
      const changedTouches = e.changedTouches;
      if (el === null || changedTouches.length === 1) {
        return new Position(changedTouches[0].clientX, changedTouches[0].clientY);
      }

      for (const touchEvent in changedTouches) {
        if (changedTouches[touchEvent].target === el) {
          return new Position(changedTouches[touchEvent].clientX, changedTouches[touchEvent].clientY);
        }
      }
      return null;
    }
  }

  static isMouseEvent(e: MouseEvent | TouchEvent): e is MouseEvent {
    return Object.prototype.toString.apply(e).indexOf('MouseEvent') === 8;
  }

  static isIPosition(obj: IPosition) {
    return !!obj && 'x' in obj && 'y' in obj;
  }

  static getCurrent(el: Element) {
    const pos = new Position(0, 0);

    if (window) {
      const computed = window.getComputedStyle(el);
      if (computed) {
        const x = parseInt(computed.getPropertyValue('left'), 10);
        const y = parseInt(computed.getPropertyValue('top'), 10);
        pos.x = isNaN(x) ? 0 : x;
        pos.y = isNaN(y) ? 0 : y;
      }
      return pos;
    } else {
      return null;
    }
  }

  get value(): IPosition {
    return { x: this.x, y: this.y };
  }

  add(p: IPosition) {
    this.x += p.x;
    this.y += p.y;
    return this;
  }

  subtract(p: IPosition) {
    this.x -= p.x;
    this.y -= p.y;
    return this;
  }

  reset() {
    this.x = 0;
    this.y = 0;
    return this;
  }

  set(p: IPosition) {
    this.x = p.x;
    this.y = p.y;
    return this;
  }
}
