<div class="modal-content-attention" [ngClass]="'modal-content-attention-' + type">
  <div class="modal-content-container">
    <div class="modal-icon" [ngClass]="'modal-icon-' + type"></div>

    <div class="modal-title">{{ title }}</div>

    <div class="modal-sub-title" *ngIf="subtitle">{{ subtitle }}</div>

    <div class="modal-message">
      <ng-container *ngTemplateOutlet="messageTemplate"></ng-container>
    </div>

    <div class="modal-buttons">
      <ng-container *ngTemplateOutlet="buttonsTemplate"></ng-container>
    </div>
  </div>
</div>
