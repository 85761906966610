import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rktShortId',
  standalone: true,
})
export class ShortIdPipe implements PipeTransform {
  transform(text: string): string {
    return text.split('-')[0].toUpperCase();
  }
}
