import { Component, HostBinding } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { InputPasswordProps } from '../form.types';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'rkt-form-input-password',
  template: `
    <input
      [type]="hidePassword ? fieldType : 'text'"
      [formControl]="formControl"
      [name]="fieldName"
      class="rkt-form-input"
      [formlyAttributes]="field"
      [class.is-invalid]="showError"
      (focus)="onFocus()"
    />
    <span class="rkt-form-input-password-visibility-toggle" (click)="hidePassword = !hidePassword">
      {{ hidePassword ? 'Show' : 'Hide' }}
    </span>
  `,
  standalone: true,
  imports: [ReactiveFormsModule, FormlyModule],
})
export class RktFormInputPasswordComponent extends FieldType<FieldTypeConfig<InputPasswordProps>> {
  @HostBinding('class.rkt-form-input-field') commonClass = true;

  get type() {
    return this.props.type || 'text';
  }

  get fieldName() {
    return this.field.name ?? (this.props.label?.toLowerCase().replace(/\s/g, '-') || '');
  }

  fieldType = 'password';

  hidePassword: boolean;

  constructor() {
    super();
    this.hidePassword = true;
  }

  onFocus() {
    if (this.props.type !== 'password') {
      return;
    }

    this.fieldType = 'password';
  }
}
