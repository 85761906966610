interface Dimensions {
  width?: number;
  height?: number;
}

interface ViewPort {
  width: number;
  height: number;
}

const getHiddenElementDimensions = (el: HTMLDivElement): Dimensions => {
  const element = el;
  const dimensions: Dimensions = {};
  element.style.visibility = 'hidden';
  element.style.display = 'block';
  dimensions.width = element.offsetWidth;
  dimensions.height = element.offsetHeight;
  element.style.display = 'none';
  element.style.visibility = 'visible';

  return dimensions;
};

const getWindowScrollTop = (): number => {
  const doc = document.documentElement;

  return (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
};

const getWindowScrollLeft = (): number => {
  const doc = document.documentElement;

  return (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
};

const getViewport = (): ViewPort => {
  const win = window;
  const d = document;
  const e = d.documentElement;
  const g = d.getElementsByTagName('body')[0];
  const gScrollWidth = g.scrollWidth;
  const w = win.innerWidth || e.clientWidth || g.clientWidth;
  const h = win.innerHeight || e.clientHeight || g.clientHeight;

  return { width: w - gScrollWidth, height: h };
};

export const absolutePosition = (el: HTMLDivElement, target?: HTMLElement): void => {
  if (!target) {
    return;
  }

  const element = el;
  const elementDimensions = element.offsetParent
    ? { width: element.offsetWidth, height: element.offsetHeight }
    : getHiddenElementDimensions(element);
  const elementOuterHeight = elementDimensions.height || 0;
  const elementOuterWidth = elementDimensions.width || 0;
  const targetOuterHeight = target.offsetHeight;
  const targetOuterWidth = target.offsetWidth;
  const targetOffset = target.getBoundingClientRect();
  const windowScrollTop = getWindowScrollTop();
  const windowScrollLeft = getWindowScrollLeft();
  const viewport = getViewport();
  let top;
  let left;

  if (targetOffset.top + targetOuterHeight + elementOuterHeight > viewport.height) {
    top = targetOffset.top + windowScrollTop - elementOuterHeight;
    if (top < 0) {
      top = windowScrollTop;
    }
  } else {
    top = targetOuterHeight + targetOffset.top + windowScrollTop;
  }

  if (targetOffset.left + elementOuterWidth > viewport.width) {
    left = Math.max(0, targetOffset.left + windowScrollLeft + targetOuterWidth - elementOuterWidth);
  } else {
    left = targetOffset.left + windowScrollLeft;
  }

  element.style.top = `${top}px`;
  element.style.left = `${left}px`;
};
