<div #tableWrapper class="rkt-table-wrapper">
  <ngx-datatable
    #datatable
    class="rkt-table"
    [class.rkt-table-sticky-header]="stickyHeader"
    [class.rkt-table-clickable]="isClickable"
    [class.rkt-table-interactive]="isInteractive"
    [class.rkt-table-items-align-center]="isItemsAlignCenter"
    [class.rkt-table-initial-loading]="isShowPlaceholder"
    [class.rkt-table-no-footer]="!isShowPlaceholder && !(count / limit > 1)"
    [rows]="
      rows
        | paginate
          : {
              id: tableId,
              itemsPerPage: limit,
              currentPage: offset + 1,
              totalItems: count,
            }
    "
    [columns]="columns"
    [columnMode]="columnMode"
    [selected]="selected"
    [limit]="limit"
    [count]="count"
    [offset]="offset"
    [externalPaging]="externalPaging"
    [externalSorting]="externalSorting"
    [loadingIndicator]="loadingIndicator"
    [selectCheck]="checkSelectable"
    [scrollbarH]="scrollbarH"
    [scrollbarV]="scrollbarV"
    [reorderable]="reorderable"
    [sortType]="sortType"
    [selectionType]="selectionType!"
    [headerHeight]="headerHeight"
    [footerHeight]="footerAndPlaceholderHeight"
    [messages]="messages"
    [rowHeight]="rowHeight"
    [sorts]="[{ prop: sortParam?.sortProp, dir: sortParam?.sortDir }]"
    (page)="setPage($event)"
    (sort)="onSort($event)"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    [rowIdentity]="getId"
    [rowClass]="getRowClass"
    [selectAllRowsOnPage]="true"
  >
    <ng-container *ngIf="detailsTemplate">
      <ngx-datatable-row-detail [template]="detailsTemplate" [rowHeight]="detailsRowHeight"></ngx-datatable-row-detail>

      <ngx-datatable-column [width]="32" [sortable]="false" [canAutoResize]="false" [draggable]="false" [resizeable]="false">
        <ng-template ngx-datatable-header-template>
          <div class="header-cell" [ngStyle]="{ 'height.px': headerHeight }">
            <label class="header-cell-inner expand-btn"> </label>
          </div>
        </ng-template>

        <ng-template ngx-datatable-cell-template let-row="row" let-expanded="expanded">
          <div class="body-cell body-cell-align-right expand-cell" [ngStyle]="{ 'height.px': rowHeight }">
            <label class="body-cell-inner expand-btn" [class.expanded]="expanded" (click)="toggleExpandRow(row)">
              <rkt-icon [name]="expanded ? 'chevron-up' : 'chevron-down'"></rkt-icon>
            </label>
          </div>
        </ng-template>
      </ngx-datatable-column>
    </ng-container>

    <ngx-datatable-column
      *ngIf="isCheckboxSelection"
      [width]="44"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
    >
      <ng-template ngx-datatable-header-template>
        <div class="header-cell header-cell-align-center checkbox-cell" [ngStyle]="{ 'height.px': headerHeight }">
          <label class="header-cell-inner checkbox-btn">
            <ng-container *ngIf="!allVisibleRowsSelected && anyVisibleRowsSelected; else checkboxIcon">
              <rkt-icon class="checkbox-icon checkbox-indeterminate" name="checkbox-indeterminate"></rkt-icon>
            </ng-container>

            <ng-template #checkboxIcon>
              <rkt-icon
                class="checkbox-icon"
                [ngClass]="'checkbox-' + (allVisibleRowsSelected ? 'on' : 'off')"
                [name]="allVisibleRowsSelected ? 'checkbox-on' : 'checkbox-off'"
              ></rkt-icon>
            </ng-template>

            <input
              hidden
              type="checkbox"
              [checked]="allVisibleRowsSelected"
              (change)="selectAll()"
              [indeterminate]="selected.length && !allVisibleRowsSelected"
            />
          </label>
        </div>
      </ng-template>
      <ng-template ngx-datatable-cell-template let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
        <div class="body-cell body-cell-align-center checkbox-cell" [ngStyle]="{ 'height.px': rowHeight }">
          <label class="body-cell-inner checkbox-btn">
            <rkt-icon
              class="checkbox-icon"
              [ngClass]="'checkbox-' + (isSelected ? 'on' : 'off')"
              [name]="isSelected ? 'checkbox-on' : 'checkbox-off'"
            ></rkt-icon>

            <input hidden type="checkbox" [checked]="isSelected" (change)="onCheckboxChangeFn($event)" />
          </label>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngFor="let col of columns"
      [prop]="col.prop!"
      [sortProp]="col.sortProp"
      [name]="col.name!"
      [cellTemplate]="col.cellTemplate"
      [colType]="col.colType"
      [sortable]="col.isSortable || false"
      [canAutoResize]="col.fixedSize ? false : col.canAutoResize!"
      [flexGrow]="col.fixedSize ? 0 : col.flexSize || 0"
      [draggable]="false"
      [resizeable]="false"
      [width]="col.fixedSize || col.width || 150"
      [minWidth]="col.fixedSize ? col.fixedSize : col.minWidth || 10"
      [maxWidth]="col.fixedSize ? col.fixedSize : col.maxWidth!"
    >
      <ng-template ngx-datatable-header-template let-value="value" let-sort="sortFn" let-sortDir="sortDir">
        <rkt-table-header-cell
          [isSortable]="col.isSortable"
          [headerHeight]="headerHeight"
          [column]="col"
          [sortDirection]="sortDir"
          (sortEmitter)="sort($event, sortDir)"
        ></rkt-table-header-cell>

        <rkt-table-placeholder-cell
          *ngIf="isShowPlaceholder"
          [column]="col"
          [placeholderItemsCount]="placeholderItemsCount"
          [rowHeight]="rowHeight"
        ></rkt-table-placeholder-cell>
      </ng-template>

      <ng-template let-item="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
        <rkt-table-body-cell
          [rowHeight]="rowHeight"
          [column]="col"
          [value]="value"
          [dateTimeFormat]="dateTimeFormat"
          [dateFormat]="dateFormat"
          [timeFormat]="timeFormat"
          [amountFormat]="amountFormat"
          [currencyFormat]="item?.currency"
          [emptyValuePlaceholder]="emptyValuePlaceholder"
          (colBtnClicked)="onButtonClick($event, item)"
          (switchButtonToggled)="onSwitchButtonToggled($event, item)"
        ></rkt-table-body-cell>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-footer>
      <ng-template ngx-datatable-footer-template>
        <rkt-table-pager
          [id]="tableId"
          [autoHide]="true"
          [limit]="limit"
          [maxSize]="paginationMaxSize"
          [paginationLabel]="paginationLabel"
          (pageChange)="setPage({ offset: $event })"
        ></rkt-table-pager>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>
</div>
