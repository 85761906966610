import { Component, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { FormlySelectOption, FormlySelectModule } from '@ngx-formly/core/select';
import { CheckboxGroupProps } from '../form.types';
import { RktFormTooltipComponent } from '../tooltip/tooltip.component';
import { RktIconComponent } from '../../icon';
import { NgFor, AsyncPipe } from '@angular/common';

@Component({
  selector: 'rkt-form-checkbox-group',
  template: `
    <div *ngFor="let option of props.options | formlySelectOptions: field | async; let i = index" class="rkt-form-checkbox">
      <label class="rkt-form-checkbox-label" [for]="id + '_' + i" [class.disabled]="option.disabled || formControl.disabled">
        <input
          hidden
          type="checkbox"
          [id]="id + '_' + i"
          class="rkt-form-checkbox-input"
          [name]="field.name || id"
          [attr.value]="option.value"
          [value]="option.value"
          [formlyAttributes]="field"
          [checked]="isChecked(option) ?? undefined"
          [attr.disabled]="option.disabled || formControl.disabled ? true : null"
          (change)="onChange(option.value, $any($event.target).checked)"
        />
        <rkt-icon [name]="isChecked(option) ? 'checkbox-on' : 'checkbox-off'" class="rkt-form-checkbox-icon"></rkt-icon>

        <div class="rkt-form-checkbox-value">
          {{ option.label }}
        </div>

        <rkt-form-tooltip [tooltip]="props.optionTooltips?.[option.value]"></rkt-form-tooltip>
      </label>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, FormlyModule, RktIconComponent, RktFormTooltipComponent, AsyncPipe, FormlySelectModule],
})
export class RktFormCheckboxGroupComponent extends FieldType<FieldTypeConfig<CheckboxGroupProps>> {
  @HostBinding('class.rkt-form-checkbox-group-field') commonClass = true;

  onChange(value: string, checked: boolean) {
    this.formControl.markAsDirty();

    this.formControl.patchValue({
      ...this.formControl.value,
      [value]: checked,
    });

    this.formControl.markAsTouched();
  }

  isChecked(option: FormlySelectOption) {
    const value = this.formControl.value;
    return value && (this.props.type === 'array' ? value.indexOf(option.value) !== -1 : value[option.value]);
  }
}
