import { Component, Input } from '@angular/core';

import { NgFor, NgClass, NgStyle } from '@angular/common';
import { DatatableColumn } from '@rocketcoms/interfaces';

@Component({
  selector: 'rkt-table-placeholder-cell',
  templateUrl: './placeholder-cell.component.html',
  standalone: true,
  imports: [NgFor, NgClass, NgStyle],
})
export class RktTablePlaceholderCellComponent {
  @Input() column!: DatatableColumn;

  @Input() rowHeight!: number;

  @Input() placeholderItemsCount!: number;
}
