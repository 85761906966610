import { ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { Subject, takeUntil } from 'rxjs';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { format, isWithinInterval, parse } from 'date-fns';
import { memoize } from 'lodash-es';
import { TimepickerProps } from '../form.types';
import { allHours, allMinutes, initialMinutes } from './timepicker-utils';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@Component({
  selector: 'rkt-form-input',
  template: `
    <div class="hour-selector rkt-form-select-field">
      <ng-select
        #selectHour
        id="hourSelect"
        labelForId="hourSelect"
        class="time-select rkt-form-select"
        [items]="hours"
        [(ngModel)]="selectedHour"
        [clearable]="false"
        [searchable]="true"
        (open)="hours = getHourOptions(hours)()"
        (change)="onHourSelectChange($event)"
        bindLabel="value"
        bindValue="value"
        placeholder="Hr"
        appendTo="body"
      >
        <ng-template ng-notfound-tmp><div class="not-found">Invalid</div></ng-template>
      </ng-select>
    </div>
    <div class="minute-selector rkt-form-select-field">
      <ng-select
        #selectMinute
        id="minuteSelect"
        labelForId="minuteSelect"
        class="time-select rkt-form-select"
        [items]="minutes"
        [(ngModel)]="selectedMinute"
        [clearable]="false"
        [searchable]="true"
        [searchFn]="minuteSearch"
        (search)="onMinuteSearch($event)"
        (open)="minutes = getMinuteOptions(minutes)()"
        (change)="onMinuteSelectChange($event)"
        (blur)="onMinuteSelectBlur()"
        [inputAttrs]="{ maxlength: '2' }"
        placeholder="Min"
        appendTo="body"
        bindLabel="value"
        bindValue="value"
      >
        <ng-template ng-notfound-tmp><div class="not-found">Invalid</div></ng-template>
      </ng-select>
    </div>
    <div class="day-time-selector">
      <div class="day-time-btn btn-left" [class.active]="dayTime === 'AM'" (click)="onDayTimeBtnClick('AM')">
        <div class="label">AM</div>
      </div>

      <div class="day-time-btn btn-right" [class.active]="dayTime === 'PM'" (click)="onDayTimeBtnClick('PM')">
        <div class="label">PM</div>
      </div>
    </div>
  `,
  standalone: true,
  imports: [NgSelectModule, ReactiveFormsModule, FormsModule],
})
export class RktFormTimepickerComponent extends FieldType<FieldTypeConfig<TimepickerProps>> implements OnInit, OnDestroy {
  @HostBinding('class.rkt-form-timepicker-field') commonClass = true;

  @ViewChild('selectHour') selectHour!: NgSelectComponent;

  @ViewChild('selectMinute') selectMinute!: NgSelectComponent;

  hours: { value: string; disabled: boolean }[] = [...allHours];

  minutes: { value: string; disabled: boolean }[] = [...initialMinutes];

  selectedHour?: string;

  selectedMinute?: string;

  dayTime = 'AM';

  get fieldName() {
    return this.field.name ?? this.props.label?.toLowerCase().replace(/\s/g, '-') ?? '';
  }

  get minTimeParsed() {
    return parse(this.props.minTime ?? '00:00', 'HH:mm', new Date());
  }

  get maxTimeParsed() {
    if (!this.props?.maxTime) {
      return;
    }

    return parse(this.props.maxTime, 'HH:mm', new Date());
  }

  private destroy$ = new Subject<void>();

  private readonly ref = inject(ChangeDetectorRef);

  ngOnInit(): void {
    if (this.formControl?.value) {
      const date = parse(this.formControl.value, 'HH:mm:ss', new Date());

      this.selectedHour = format(date, 'h');
      this.selectedMinute = format(date, 'mm');
      this.dayTime = format(date, 'aa');
    }

    this.formControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
      next: (value) => {
        const date = parse(value, 'HH:mm:ss', new Date());

        this.selectedHour = format(date, 'h');
        this.selectedMinute = format(date, 'mm');
        this.dayTime = format(date, 'aa');
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getTimeFormatted(): void {
    if (!this.selectedHour || !this.selectedMinute) {
      return;
    }

    const formattedTime = format(parse(`${this.selectedHour}:${this.selectedMinute} ${this.dayTime}`, 'h:mm a', new Date()), 'HH:mm:ss');

    this.formControl.setValue(formattedTime);
  }

  onHourSelectChange(selectedHour: { value: string }): void {
    this.selectedHour = selectedHour.value;
    this.selectHour.blur();
    this.getTimeFormatted();
  }

  onMinuteSelectChange(selectedMinute: { value: string }): void {
    this.selectedMinute = selectedMinute.value;
    this.selectMinute.blur();

    this.getTimeFormatted();
  }

  onDayTimeBtnClick(dayTime: 'AM' | 'PM'): void {
    if (dayTime === this.dayTime) {
      return;
    }

    this.dayTime = dayTime;
    this.getTimeFormatted();
    this.hours = [...allHours];
    this.minutes = [...initialMinutes];
  }

  minuteSearch(term: string, item: { value: string; disabled: boolean }): boolean {
    return item?.value.startsWith(term);
  }

  onMinuteSearch(item: { term?: string }): void {
    if (item.term) {
      this.minutes = this.getMinuteOptions([...allMinutes])();
    } else {
      this.minutes = this.getMinuteOptions([...initialMinutes])();
    }
  }

  onMinuteSelectBlur(): void {
    this.minutes = [...initialMinutes];
  }

  getHourOptions(items: { value: string; disabled: boolean }[]) {
    const hourItems = items.map((item) => {
      const timeOption = parse(`${item.value}:${this.selectedMinute ?? '00'} ${this.dayTime}`, 'h:mm a', new Date());
      const isDisabled =
        this.maxTimeParsed && this.minTimeParsed
          ? !isWithinInterval(timeOption, { start: this.minTimeParsed, end: this.maxTimeParsed })
          : false;
      return { ...item, disabled: isDisabled };
    });

    return memoize(
      () => hourItems,
      () => [items, this.maxTimeParsed, this.minTimeParsed],
    );
  }

  getMinuteOptions(items: { value: string; disabled: boolean }[]) {
    const minuteItems = items.map((item) => {
      const timeOption = parse(`${this.selectedHour ?? '12'}:${item.value} ${this.dayTime}`, 'h:mm a', new Date());
      const isDisabled =
        this.maxTimeParsed && this.minTimeParsed
          ? !isWithinInterval(timeOption, { start: this.minTimeParsed!, end: this.maxTimeParsed! })
          : false;
      return { ...item, disabled: isDisabled };
    });

    return memoize(
      () => minuteItems,
      () => [items, this.maxTimeParsed, this.minTimeParsed],
    );
  }
}
