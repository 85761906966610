import { FormlyFieldConfig } from '@ngx-formly/core';
import { CheckboxGroupProps, FieldHelperProps } from '../form.types';

export const formCheckboxGroup = (config: FieldHelperProps<CheckboxGroupProps>): FormlyFieldConfig => {
  const defaultClassName = 'rkt-form-control';
  return {
    key: config.key,
    type: 'checkbox-group',
    className: config.className ? `${defaultClassName} ${config.className}` : defaultClassName,
    defaultValue: config.defaultValue,
    props: {
      label: config.label,
      ...config.props,
    },
    validators: { ...config.validators },
    validation: { ...config.validation },
    expressions: { ...config.expressions },
  };
};
