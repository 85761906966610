import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RktButtonDirective, RktIconComponent, ActiveModal } from '@rocketcoms/rocket-ui';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { LegalService } from '@rocketcoms/data-access';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RktIconComponent, NgxExtendedPdfViewerModule, RktButtonDirective],
})
export class TermsConditionsComponent {
  url = './files/RocketFNCL_Terms_and_Conditions_220427.pdf';

  constructor(
    public activeModal: ActiveModal,
    private legalService: LegalService,
  ) {}

  downloadTermsConditions() {
    this.legalService.downloadTermsConditions();
  }
}
