import { Injectable } from '@angular/core';
import { IndividualConfig } from 'ngx-toastr';

import { Notification } from '@rocketcoms/interfaces';

import { NgxToastrService } from './ngx-toastr.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private ngxToastrService: NgxToastrService) {}

  success(notification: Notification, messageConfig?: Partial<IndividualConfig>): void {
    this.ngxToastrService.show(notification, 'toast-success', messageConfig);
  }

  error(notification: Notification, messageConfig?: Partial<IndividualConfig>): void {
    this.ngxToastrService.show(notification, 'toast-error', messageConfig);
  }

  warning(notification: Notification, messageConfig?: Partial<IndividualConfig>): void {
    this.ngxToastrService.show(notification, 'toast-warning', messageConfig);
  }

  info(notification: Notification, messageConfig?: Partial<IndividualConfig>): void {
    this.ngxToastrService.show(notification, 'toast-info', messageConfig);
  }

  clearAll(): void {
    this.ngxToastrService.clear();
  }
}
