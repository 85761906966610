import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RktButtonDirective, RktIconComponent, ActiveModal } from '@rocketcoms/rocket-ui';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { LegalService } from '@rocketcoms/data-access';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RktIconComponent, NgxExtendedPdfViewerModule, RktButtonDirective],
})
export class PrivacyPolicyComponent {
  url = './files/RocketFNCL_Privacy_Policy_220427.pdf';

  constructor(
    public activeModal: ActiveModal,
    private legalService: LegalService,
  ) {}

  downloadPrivacyPolicy() {
    this.legalService.downloadPrivacyPolicy();
  }
}
