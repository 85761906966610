import { Component, Input } from '@angular/core';
import { RktButtonDirective, ActiveModal } from '@rocketcoms/rocket-ui';

import { AttentionModalComponent } from '../attention-modal/attention-modal.component';

@Component({
  selector: 'app-success-modal',
  templateUrl: 'success-modal.component.html',
  styleUrls: ['success-modal.component.scss'],
  standalone: true,
  imports: [AttentionModalComponent, RktButtonDirective],
})
export class SuccessModalComponent {
  @Input() title = 'Successful!';

  @Input() text!: string;

  constructor(public activeModal: ActiveModal) {}
}
