import { Renderer2 } from '@angular/core';

export class HelperBlock {
  protected helperEl: Element | null;
  private isAdded = false;

  get el() {
    return this.helperEl;
  }

  constructor(
    protected parent: Element,
    protected renderer: Renderer2,
  ) {
    const helper = renderer.createElement('div');
    renderer.setStyle(helper, 'position', 'absolute');
    renderer.setStyle(helper, 'width', '100%');
    renderer.setStyle(helper, 'height', '100%');
    renderer.setStyle(helper, 'background-color', 'transparent');
    renderer.setStyle(helper, 'top', '0');
    renderer.setStyle(helper, 'left', '0');

    this.helperEl = helper;
  }

  add(): void {
    if (this.parent && !this.isAdded && this.helperEl) {
      this.parent.appendChild(this.helperEl);
      this.isAdded = true;
    }
  }

  remove(): void {
    if (this.parent && this.isAdded && this.helperEl) {
      this.parent.removeChild(this.helperEl);
      this.isAdded = false;
    }
  }

  dispose(): void {
    this.helperEl = null;
    this.isAdded = false;
  }
}
