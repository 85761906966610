import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { SelectCountryStateProps } from '../form.types';
import { getStateListSelectOptions } from '../utils';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { AsyncPipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
  selector: 'rkt-form-select-state',
  template: `
    <ng-select
      #selectState
      class="rkt-form-select"
      [class.is-invalid]="showError"
      [items]="countryStateList | formlySelectOptions: field | async"
      [placeholder]="to.placeholder || ''"
      [tabIndex]="to.tabindex || 0"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [bindLabel]="labelProp"
      [bindValue]="valueProp"
      [clearable]="true"
      [searchable]="true"
      [virtualScroll]="true"
      (close)="selectState.blur()"
      [selectOnTab]="false"
      [closeOnSelect]="true"
      notFoundText="State not found"
    >
      <ng-template ng-label-tmp let-item="item">
        <div class="ng-value-label ng-country-value-label">
          <span class="option-value">{{ item['label'] }}</span>
        </div>
      </ng-template>

      <ng-template ng-option-tmp let-item="item">
        <div class="ng-option-label ng-country-value-label">
          <span class="option-value">{{ item['label'] }}</span>
        </div>
      </ng-template>
    </ng-select>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgSelectModule, ReactiveFormsModule, FormlyModule, AsyncPipe, FormlySelectModule],
})
export class RktFormSelectStateComponent extends FieldType<FieldTypeConfig<SelectCountryStateProps>> implements OnInit {
  @HostBinding('class.rkt-form-select-field') commonClass = true;

  @HostBinding('class.rkt-form-select-country-field') stateSelectClass = true;

  get labelProp(): string {
    return this.props.labelProp || 'label';
  }

  get valueProp(): string {
    return this.props.valueProp || 'value';
  }

  countryStateList: { label: string; value: string }[] = [];

  ngOnInit(): void {
    this.countryStateList = getStateListSelectOptions(this.props?.countryCode3);
  }
}
