import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appRemoveFocus]',
  standalone: true,
})
export class FocusRemoveDirective {
  constructor(private elementRef: ElementRef) {}

  @HostListener('click') onClick() {
    this.elementRef.nativeElement.blur();
  }
}
