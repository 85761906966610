import { FieldHelperProps, FormlyInputFieldConfig, InputProps } from '../form.types';

export const formInput = (config: FieldHelperProps<InputProps>): FormlyInputFieldConfig => {
  const defaultClassName = 'rkt-form-control';

  return {
    key: config.key,
    name: config.name,
    type: 'input',
    className: config.className ? `${defaultClassName} ${config.className}` : defaultClassName,
    defaultValue: config.defaultValue,
    props: {
      label: config.label,
      ...config.props,
    },
    parsers: config.parsers,
    modelOptions: { ...config.modelOptions },
    validators: { ...config.validators },
    validation: { ...config.validation },
    expressions: { ...config.expressions },
  };
};
