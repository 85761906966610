export const initialMinutes = [
  { value: '00', disabled: false },
  { value: '15', disabled: false },
  { value: '30', disabled: false },
  { value: '45', disabled: false },
];

export const allMinutes: { value: string; disabled: boolean }[] = Array.from(Array(60).keys(), (key) => {
  return { value: String(key).padStart(2, '0'), disabled: false };
});

export const allHours = [
  { value: '12', disabled: false },
  { value: '1', disabled: false },
  { value: '2', disabled: false },
  { value: '3', disabled: false },
  { value: '4', disabled: false },
  { value: '5', disabled: false },
  { value: '6', disabled: false },
  { value: '7', disabled: false },
  { value: '8', disabled: false },
  { value: '9', disabled: false },
  { value: '10', disabled: false },
  { value: '11', disabled: false },
];
