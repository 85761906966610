import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyPresetModule } from '@ngx-formly/core/preset';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RktIconRegistryService, card, checkboxOff, checkboxOn, info, plus, trash, calendar, close } from '../icon';
import { cardNumberValidator, countryCodeValidator, phoneNumberValidator, stateCodeValidator } from './validators';
import { formInput, formInputPhone, formInputPlace } from './helpers';
import { ClickOutsideDirective, RktFormInputPhoneComponent } from './input-phone/input-phone.component';
import { RktFormComponent } from './form.component';
import { RktFormFieldWrapperComponent } from './field-wrapper/field-wrapper';
import { RktFormInputComponent } from './input/input.component';
import { RktFormSelectComponent } from './select/select.component';
import { RktFormTextareaComponent } from './textarea/textarea.component';
import { RktFormInputPlaceComponent } from './input-place/input-place.component';
import { RktFormInputPasswordComponent } from './input-password/input-password.component';
import { RktFormRadioGroupComponent } from './radio-group/radio-group.component';
import { RktFormlyFieldGroupComponent } from './field-group/field-group';
import { minLengthValidationMessage, requiredValidationMessage } from './validators/messages';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { RktFormInputCardComponent } from './input-card/input-card.component';
import { RktFormRepeatComponent } from './repeat/repeat.component';
import { RktFormCheckboxGroupComponent } from './checkbox-group/checkbox-group.component';
import { RktFormTooltipComponent } from './tooltip/tooltip.component';
import { RktFormSelectCountryComponent } from './select-country/select-country.component';
import { DateInputDirective, RktFormDatepickerComponent } from './datepicker/datepicker.component';
import { RktFormInputSecureComponent } from './input-secure/input-secure.component';
import { RktFormRepeatSectionComponent } from './repeat/repeat-section.component';
import { RktFormTimepickerComponent } from './timepicker/timepicker.component';
import { RktFormSelectMultiComponent } from './select-multi/select-multi.component';
import { RktFormSelectStateComponent } from './select-state/select-state.component';

const components = [
  RktFormlyFieldGroupComponent,
  RktFormFieldWrapperComponent,
  RktFormComponent,
  RktFormInputComponent,
  RktFormInputPhoneComponent,
  RktFormSelectComponent,
  RktFormTextareaComponent,
  RktFormInputPlaceComponent,
  RktFormInputPasswordComponent,
  RktFormRadioGroupComponent,
  RktFormInputCardComponent,
  RktFormRepeatComponent,
  RktFormCheckboxGroupComponent,
  RktFormTooltipComponent,
  RktFormSelectCountryComponent,
  RktFormSelectStateComponent,
  RktFormDatepickerComponent,
  RktFormInputSecureComponent,
  RktFormRepeatSectionComponent,
  RktFormTimepickerComponent,
  RktFormSelectMultiComponent,
];
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
    FormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    FormlyPresetModule,
    FormlySelectModule,
    FormlyModule.forRoot({
      types: [
        { name: 'formly-group', component: RktFormlyFieldGroupComponent },
        {
          name: 'input',
          component: RktFormInputComponent,
          wrappers: ['field-wrapper'],
        },
        {
          name: 'input-phone',
          component: RktFormInputPhoneComponent,
          wrappers: ['field-wrapper'],
        },
        {
          name: 'input-place',
          component: RktFormInputPlaceComponent,
          wrappers: ['field-wrapper'],
        },
        {
          name: 'input-password',
          component: RktFormInputPasswordComponent,
          wrappers: ['field-wrapper'],
        },
        {
          name: 'input-card',
          component: RktFormInputCardComponent,
          wrappers: ['field-wrapper'],
        },
        {
          name: 'input-secure',
          component: RktFormInputSecureComponent,
          wrappers: ['field-wrapper'],
        },
        {
          name: 'number',
          extends: 'input',
          defaultOptions: {
            props: {
              type: 'number',
            },
          },
        },
        {
          name: 'select',
          component: RktFormSelectComponent,
          wrappers: ['field-wrapper'],
        },
        {
          name: 'select-country',
          component: RktFormSelectCountryComponent,
          wrappers: ['field-wrapper'],
        },
        {
          name: 'select-state',
          component: RktFormSelectStateComponent,
          wrappers: ['field-wrapper'],
        },
        {
          name: 'select-multi',
          component: RktFormSelectMultiComponent,
          wrappers: ['field-wrapper'],
        },
        {
          name: 'textarea',
          component: RktFormTextareaComponent,
          wrappers: ['field-wrapper'],
        },
        {
          name: 'radio-group',
          component: RktFormRadioGroupComponent,
          wrappers: ['field-wrapper'],
        },
        {
          name: 'checkbox-group',
          component: RktFormCheckboxGroupComponent,
          wrappers: ['field-wrapper'],
        },
        {
          name: 'repeat',
          component: RktFormRepeatComponent,
          wrappers: ['field-wrapper'],
        },
        {
          name: 'repeat-section',
          component: RktFormRepeatSectionComponent,
          wrappers: ['field-wrapper'],
        },
        {
          name: 'datepicker',
          component: RktFormDatepickerComponent,
          wrappers: ['field-wrapper'],
        },
        {
          name: 'timepicker',
          component: RktFormTimepickerComponent,
          wrappers: ['field-wrapper'],
        },
      ],
      wrappers: [{ name: 'field-wrapper', component: RktFormFieldWrapperComponent }],
      validationMessages: [
        { name: 'required', message: requiredValidationMessage },
        { name: 'minLength', message: minLengthValidationMessage },
      ],
      presets: [
        {
          name: 'firstName',
          config: formInput({
            key: 'firstName',
            label: 'First name',
            props: { required: true },
          }),
        },
        {
          name: 'middleName',
          config: formInput({ key: 'middleName', label: 'Middle name' }),
        },
        {
          name: 'lastName',
          config: formInput({
            key: 'lastName',
            label: 'Last name',
            props: { required: true },
          }),
        },
        {
          name: 'email',
          config: formInput({
            key: 'email',
            label: 'Email address',
            props: { required: true, minLength: 1, maxLength: 100 },
          }),
        },
        {
          name: 'phoneNumber',
          config: formInputPhone({
            key: 'phoneNumber',
            label: 'Mobile number',
            className: 'half-row-field',
            props: {
              required: true,
              minLength: 10,
              maxLength: 40,
              countryRestrictions: ['CA', 'US'],
            },
            validators: { validation: [{ name: 'isValidPhoneNumber' }] },
          }),
        },
        {
          name: 'addressLine1',
          config: formInputPlace({
            key: 'addressLine1',
            label: 'Workplace Address 1',
            props: { required: true, countryRestrictions: ['CA', 'US'] },
          }),
        },
      ],
      validators: [
        {
          name: 'isValidPhoneNumber',
          validation: phoneNumberValidator,
        },
        {
          name: 'isValidCountryCode',
          validation: countryCodeValidator,
        },
        {
          name: 'isValidStateCode',
          validation: stateCodeValidator,
        },
        {
          name: 'isValidCardNumber',
          validation: cardNumberValidator,
        },
      ],
    }),
    ...components,
    ClickOutsideDirective,
    DateInputDirective,
  ],
  exports: [RktFormComponent, FormsModule, ReactiveFormsModule],
  providers: [provideNgxMask()],
})
export class RktFormModule {
  constructor(private iconService: RktIconRegistryService) {
    this.iconService.registerIcons([card, trash, plus, checkboxOn, checkboxOff, info, calendar, close]);
  }
}
