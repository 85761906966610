<div class="placeholder-cell">
  <div
    class="body-cell"
    [ngClass]="[
      column.prop ? 'body-cell-' + column.prop : '',
      column.className ? 'body-cell-' + column.className : '',
      'body-cell-align-' + (column.colAlign || 'left'),
    ]"
    [ngStyle]="{ 'height.px': rowHeight }"
    *ngFor="let i of [].constructor(placeholderItemsCount)"
  >
    <div class="placeholder-cell-inner"></div>
  </div>
</div>
