import { Component, Input } from '@angular/core';
import { RktIconComponent } from '@rocketcoms/rocket-ui';

import { NotAvailablePipe } from '@rocketcoms/utils';

import { TextWithPlaceholderComponent } from '../text-with-placeholder/text-with-placeholder.component';

@Component({
  selector: 'app-details-item',
  templateUrl: 'details-item.component.html',
  standalone: true,
  imports: [RktIconComponent, TextWithPlaceholderComponent, NotAvailablePipe],
})
export class DetailsItemComponent {
  @Input() label = '';

  @Input() value?: string | null;

  @Input() tooltip?: string | null;

  @Input() noBreak!: boolean;

  @Input() showPlaceholder = false;

  @Input() height = '1.125';

  @Input() width!: string;

  @Input() placeholderRows = 1;

  @Input() templateValueOnly = false;

  isTooltipDisplayed = false;

  get placeholderWidth(): string {
    return this.width || `${this.label.split('').length}`;
  }

  get minHeight(): number {
    const placeholderHeight = 1.4375;
    if (!this.showPlaceholder && !this.value) {
      return placeholderHeight;
    }

    return placeholderHeight * this.placeholderRows;
  }

  showTooltip(): void {
    if (this.isTooltipDisplayed) {
      return;
    }

    this.isTooltipDisplayed = true;
  }

  hideTooltip(): void {
    if (!this.isTooltipDisplayed) {
      return;
    }

    this.isTooltipDisplayed = false;
  }
}
