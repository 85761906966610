import {
  Component,
  ElementRef,
  OnDestroy,
  Input,
  Renderer2,
  ViewChild,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ViewEncapsulation,
} from '@angular/core';
import { trigger, style, transition, animate, AnimationEvent } from '@angular/animations';
import { MenuItem } from './menu.types';
import { absolutePosition } from './utils';
import { RktIconComponent } from '../icon';
import { NgIf, NgClass, NgFor } from '@angular/common';

@Component({
  selector: 'rkt-menu',
  template: `
    <div
      #container
      class="rkt-menu"
      [style.width.px]="width"
      [ngClass]="className"
      (click)="preventDocumentDefault = true"
      *ngIf="visible"
      [@overlayAnimation]="{
        value: 'visible',
        params: {
          showTransitionParams: showTransitionOptions,
          hideTransitionParams: hideTransitionOptions,
        },
      }"
      (@overlayAnimation.start)="onOverlayAnimationStart($event)"
    >
      <div class="rkt-menu-list">
        <ng-template ngFor let-item [ngForOf]="model">
          <div *ngIf="item.type === 'link'" class="rkt-menu-item--link">
            <a [attr.href]="null" class="rkt-menu-item-link" [attr.tabindex]="0" (click)="itemClick($event, item)" role="menuitem">
              <span class="rkt-menu-item-icon"><rkt-icon [name]="item.iconName" *ngIf="item.iconName"></rkt-icon></span>
              <span class="rkt-menu-item-text">{{ item.label }}</span>
            </a>
          </div>
          <div *ngIf="item.type === 'separator'" class="rkt-menu-item--separator"></div>
          <div *ngIf="item.type === 'text'" class="rkt-menu-item--static-text">
            <span class="rkt-menu-item-text">{{ item.label }}</span>
          </div>
        </ng-template>
      </div>
    </div>
  `,
  animations: [
    trigger('overlayAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scaleY(0.8)' }), animate('{{showTransitionParams}}')]),
      transition(':leave', [animate('{{hideTransitionParams}}', style({ opacity: 0 }))]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./menu.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, NgFor, RktIconComponent],
})
export class RktMenuComponent implements OnDestroy {
  @ViewChild('container') containerViewChild!: ElementRef;

  @Input() model!: MenuItem[];

  @Input() width?: number;

  @Input() className = '';

  showTransitionOptions = '.12s cubic-bezier(0, 0, 0.2, 1)';

  hideTransitionOptions = '.1s linear';

  container!: HTMLDivElement;

  documentResizeListener!: () => void;

  preventDocumentDefault?: boolean;

  elementId?: string;

  target?: HTMLElement;

  visible = false;

  private documentClickListener?: () => void;

  constructor(
    public el: ElementRef,
    public renderer: Renderer2,
    private changeDetector: ChangeDetectorRef,
  ) {}

  toggle(event: Event, id?: string) {
    if (this.visible) {
      if (this.target !== event.currentTarget) {
        this.show(event);
        this.elementId = id;
        this.alignOverlay();
      } else {
        this.hide();
        this.elementId = undefined;
      }
    } else {
      this.show(event);
      this.elementId = id;
    }

    this.preventDocumentDefault = true;
  }

  show(event: Event) {
    this.target = event.currentTarget as HTMLElement;

    this.visible = true;
    this.preventDocumentDefault = true;
    this.changeDetector.markForCheck();
  }

  onOverlayAnimationStart(event: AnimationEvent) {
    switch (event.toState) {
      case 'visible':
        this.container = event.element;
        this.appendOverlay();
        this.alignOverlay();
        this.bindDocumentClickListener();
        this.bindDocumentResizeListener();
        break;

      case 'void':
        this.onOverlayHide();
        break;

      default:
        break;
    }
  }

  alignOverlay() {
    absolutePosition(this.container, this.target);
  }

  appendOverlay() {
    document.body.appendChild(this.container);
  }

  hide() {
    this.visible = false;
    this.changeDetector.markForCheck();
  }

  onWindowResize() {
    this.hide();
  }

  itemClick(event: Event, item: MenuItem) {
    if (!item.url) {
      event.preventDefault();
    }

    if (item.command) {
      item.command({
        originalEvent: event,
        item,
        elementId: this.elementId,
      });
    }

    this.hide();
  }

  bindDocumentClickListener() {
    if (!this.documentClickListener) {
      this.documentClickListener = this.renderer.listen('document', 'click', () => {
        if (!this.preventDocumentDefault) {
          this.hide();
        }

        this.preventDocumentDefault = false;
      });
    }
  }

  unbindDocumentClickListener() {
    if (this.documentClickListener) {
      this.documentClickListener();
      delete this.documentClickListener;
    }
  }

  bindDocumentResizeListener() {
    this.documentResizeListener = this.onWindowResize.bind(this);
    window.addEventListener('resize', this.documentResizeListener);
  }

  unbindDocumentResizeListener() {
    if (this.documentResizeListener) {
      window.removeEventListener('resize', this.documentResizeListener);
      delete this.documentClickListener;
    }
  }

  onOverlayHide() {
    this.unbindDocumentClickListener();
    this.unbindDocumentResizeListener();
    this.preventDocumentDefault = false;
    this.target = undefined;
  }

  ngOnDestroy() {
    if (this.container) {
      this.el.nativeElement.appendChild(this.container);
    }
    this.onOverlayHide();
  }
}
