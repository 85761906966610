import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { isEmpty, isNull } from 'lodash-es';

import { fromRoot } from '../../..';

import * as fromAuth from './auth.reducer';
import * as fromLoginPage from './login-page.reducer';

export const authFeatureKey = 'auth';

export const DEFAULT_TOKEN_EXPIRE_IN_MILLISECONDS = 600000; // 10 minutes

export interface AuthRootState {
  [fromAuth.authFeatureKey]: fromAuth.AuthState;
  [fromLoginPage.loginPageFeatureKey]: fromLoginPage.LoginPageState;
}

export interface State extends fromRoot.RootState {
  [authFeatureKey]: AuthRootState;
}

export const reducers = (state: AuthRootState | undefined, action: Action) => {
  return combineReducers({
    [fromAuth.authFeatureKey]: fromAuth.reducer,
    [fromLoginPage.loginPageFeatureKey]: fromLoginPage.reducer,
  })(state, action);
};

export const selectAuthState = createFeatureSelector<AuthRootState>(authFeatureKey);

// Auth State selectors
export const selectAuthStatusState = createSelector(selectAuthState, (state) => state.status);
export const selectBrand = createSelector(selectAuthStatusState, fromAuth.getBrand);
export const selectTokenInfo = createSelector(selectAuthStatusState, fromAuth.getToken);

// Auth User selectors
export const selectUser = createSelector(selectAuthStatusState, fromAuth.getUser);
// export const selectActiveUserId = createSelector(selectUser, (user) => user?.id ?? null);
// export const selectActiveUserBusinessAccountId = createSelector(selectUser, (user) => user?.businessAccount?.id ?? null);
export const selectLoggedIn = createSelector(selectUser, (user) => !!user);
// export const selectBusinessAccountFilters = createSelector(selectUser, (user) => user?.businessAccount?.filterWords ?? []);

export const selectSystemFilterSearch = createSelector(selectAuthStatusState, (state) => state.systemFilterSearch);

// export const selectBusinessAccountFiltersSearch = createSelector(
//   selectBusinessAccountFilters,
//   selectSystemFilterSearch,
//   (filters, searchString) =>
//     isUndefined(searchString)
//       ? filters
//       : filters?.filter((item) => {
//           return (
//             item.name?.toLowerCase().indexOf(searchString) !== -1 ||
//             item.words.filter((word) => word?.toLowerCase().indexOf(searchString) !== -1).length
//           );
//         }),
// );

// export const selectBusinessAccountFiltersSearchTotalElements = createSelector(
//   selectBusinessAccountFiltersSearch,
//   (filters) => filters.length || 0,
// );

// Auth Token selectors
export const selectToken = createSelector(selectTokenInfo, (tokenInfo) => tokenInfo?.token ?? null);

export const selectTokenExpireInMilliseconds = createSelector(
  selectTokenInfo,
  (tokenInfo) => tokenInfo?.expiry ?? DEFAULT_TOKEN_EXPIRE_IN_MILLISECONDS,
);

// Login Page selectors
export const selectLoginPageState = createSelector(selectAuthState, (state) => state.loginPage);
export const selectLoginPageError = createSelector(selectLoginPageState, fromLoginPage.getError);
export const selectLoginPagePending = createSelector(selectLoginPageState, fromLoginPage.getPending);

// Collections
export const selectCollections = createSelector(selectAuthStatusState, fromAuth.getCollections);
export const selectVerticals = createSelector(selectCollections, (collections) => collections?.verticals);
export const selectUsecases = createSelector(selectCollections, (collections) => collections?.usecases);
export const selectEntityTypes = createSelector(selectCollections, (collections) => collections?.entityTypes);
export const selectBrandRelationships = createSelector(selectCollections, (collections) => collections?.brandRelationships);
export const selectTemplatePlaceholders = createSelector(selectCollections, (collections) =>
  collections?.templatePlaceholders ? Object.values(collections?.templatePlaceholders) : [],
);

export const selectVerticalByName = (verticalName: string) =>
  createSelector(selectVerticals, (verticals) => verticals?.find((item) => item.verticalName === verticalName));

export const selectEntityTypeByName = (entityType: string) =>
  createSelector(selectEntityTypes, (entityTypes) => entityTypes?.find((item) => item.id === entityType));

export const selectUseCaseByName = (useCase: string) =>
  createSelector(selectUsecases, (useCases) => useCases?.find((item) => item.usecaseName === useCase));

// Brands
export const selectBrandList = createSelector(selectAuthStatusState, fromAuth.getBrandList);
export const selectBrandListItems = createSelector(selectBrandList, (brandList) => brandList);
export const selectBrandListLoading = createSelector(selectBrandList, (brandList) => brandList === null);
export const selectBrandListEmpty = createSelector(selectBrandList, (brandList) => !isNull(brandList) && isEmpty(brandList));

// Main Nav selectors
export const selectMenuExpanded = createSelector(selectAuthStatusState, fromAuth.getMenuExpanded);

export const selectTemplatePlaceholdersWithSearch = (searchString?: string) =>
  createSelector(
    selectTemplatePlaceholders,

    (placeholders) => {
      if (!searchString) {
        return placeholders;
      }

      return placeholders.filter((item) => item?.toLowerCase().indexOf(searchString!) !== -1);
    },
  );
