import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { camelCase, compact, startCase } from 'lodash-es';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

const checkIfValidUUID = (value: string) => {
  // Regular expression to check if string is a valid UUID
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return regexExp.test(value);
};

export const PageTitleFactory = (router: Router, titleService: Title) => (): Subscription =>
  router.events.pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd)).subscribe({
    next: (path) => {
      const routePath = compact(path.url.split('?')[0].split('/'));
      const route = [];

      if (['brands', 'campaigns'].includes(routePath[0])) {
        route.push(routePath[0].slice(0, -1));
      } else if (['settings'].includes(routePath[0]) && routePath[1]) {
        route.push(routePath[1] === 'change-password' ? 'Change Password' : `${routePath[0]} ${routePath[1]}`);
      } else if (['auth'].includes(routePath[0]) && !routePath[1]) {
        route.push('Sign in');
      } else if (['auth'].includes(routePath[0]) && routePath[1]) {
        route.push('');
      } else {
        route.push(routePath[0]);
      }

      if (['brands', 'campaigns'].includes(routePath[0]) && !routePath[1]) {
        route.push('list');
      }

      if (routePath[1]) {
        if (routePath[1] === 'add') {
          route.push('registry');
        } else if (checkIfValidUUID(routePath[1])) {
          route.push('details');
        } else if (routePath[0] === 'settings') {
          route.push('');
        } else {
          route.push('' + routePath[1]);
        }
      }

      const title = `${startCase(camelCase(route.join(' ')))}`;

      titleService.setTitle(`RocketCOMS - ${title}`);
    },
  });
