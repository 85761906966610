import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { AuthResetParams, Brand, Collections, TokenInfo, UserRaw } from '@rocketcoms/interfaces';

export const AuthActions = createActionGroup({
  source: 'Auth Actions API',
  events: {
    loginSuccess: props<{ user: UserRaw; tokenInfo: TokenInfo; brand: Brand | null }>(),
    loginFailure: props<{ error: string }>(),
    loginForbidden: emptyProps,
    logout: props<{ params?: AuthResetParams }>(),
    logoutCompleted: props<{ params?: AuthResetParams }>(),
    refreshToken: emptyProps,
    refreshTokenSuccess: props<{ tokenInfo: TokenInfo }>(),
    noActiveSession: emptyProps,
    rehydrateState: emptyProps,
    userUpdateSuccess: props<{ user: UserRaw }>(),
    collectionsSuccess: props<{ collections: Collections }>(),
    brandList: emptyProps,
    brandListSuccess: props<{ brandList: Brand[] }>(),
    brandListFailure: props<{ error: string }>(),
    brandSelect: props<{ brand: Brand | null }>(),
    brandSelectById: props<{ brandId: Brand['id'] | null }>(),
    selectedBrand: props<{ brand: Brand | null }>(),
    brandRefetch: emptyProps,
    brandRefetchUnchanged: emptyProps,
    brandRefetchSkipped: emptyProps,
    brandRefetchSuccess: emptyProps,
    menuExpand: emptyProps,
    menuCollapse: emptyProps,
    systemFilterSearch: props<{ searchString?: string }>(),
  },
});
