import { Component } from '@angular/core';
import { FieldWrapper, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { RktFormFieldProps } from '../form.types';
import { RktFormTooltipComponent } from '../tooltip/tooltip.component';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import { TooltipDirective } from '@rocketcoms/utils';
import { RktIconComponent } from '../../icon';

@Component({
  selector: 'rkt-form-field-wrapper',
  template: `
    <ng-template #labelTemplate>
      <div class="rkt-form-label">
        <label *ngIf="props.label && props.hideLabel !== true" [attr.for]="id"
          >{{ props.label }}<span class="asterisk" *ngIf="props.required && props.hideRequiredMarker !== true" aria-hidden="true">*</span>
        </label>

        @if (props.fieldTooltip) {
          <rkt-icon class="default-tooltip-icon" name="info" [rktTooltip]="props.fieldTooltip"></rkt-icon>
        }
      </div>
    </ng-template>

    <div class="rkt-form-field" [class.has-error]="showError">
      <ng-container [ngTemplateOutlet]="labelTemplate"></ng-container>

      <ng-template #fieldComponent></ng-template>

      <div *ngIf="showError" class="rkt-form-field-invalid-feedback">
        <formly-validation-message [field]="field"></formly-validation-message>
      </div>

      <div *ngIf="props.description">{{ props.description }}</div>

      <div *ngIf="props.fieldLinkClick" class="rkt-form-field-link-container">
        <button tabindex="-1" (click)="props.fieldLinkClick()" class="rkt-form-field-link" type="button">{{ props.fieldLinkText }}</button>
      </div>
    </div>
  `,
  standalone: true,
  imports: [NgIf, RktFormTooltipComponent, NgTemplateOutlet, FormlyModule, TooltipDirective, RktIconComponent],
})
export class RktFormFieldWrapperComponent extends FieldWrapper<FormlyFieldConfig<RktFormFieldProps>> {}
