import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rktSplit',
  standalone: true,
})
export class SplitPipe implements PipeTransform {
  transform(text: string, by: string, index = 1): string {
    const arr = text.split(by);
    return arr[index];
  }
}
