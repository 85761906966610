import { createReducer, on } from '@ngrx/store';

import { Brand, Collections, ErrorMessage, TokenInfo, UserRaw } from '@rocketcoms/interfaces';

import { AuthActions } from './auth.actions';

export const authFeatureKey = 'status';

export interface AuthState {
  user: UserRaw | null;
  brand: Brand | null;
  isLoading: boolean;
  error: ErrorMessage;
  tokenInfo: TokenInfo | null;
  collections: Collections | null;
  brandList: Brand[] | null;
  isMenuExpanded: boolean;
  systemFilterSearch: string | undefined;
}

export const initialAuthState: AuthState = {
  user: null,
  brand: null,
  isLoading: false,
  error: null,
  tokenInfo: null,
  collections: null,
  brandList: null,
  isMenuExpanded: false,
  systemFilterSearch: undefined,
};

export const reducer = createReducer(
  initialAuthState,
  on(AuthActions.loginSuccess, (state, { user, tokenInfo }): AuthState => {
    return { ...state, user, tokenInfo, brand: null };
  }),
  on(AuthActions.logoutCompleted, (): AuthState => initialAuthState),
  on(AuthActions.loginForbidden, (): AuthState => initialAuthState),
  on(AuthActions.refreshTokenSuccess, (state, { tokenInfo }): AuthState => {
    return { ...state, tokenInfo };
  }),
  on(AuthActions.userUpdateSuccess, (state, { user }): AuthState => {
    return { ...state, user };
  }),
  on(AuthActions.collectionsSuccess, (state, { collections }): AuthState => {
    return { ...state, collections };
  }),
  on(AuthActions.brandList, (state): AuthState => {
    return { ...state, brandList: null };
  }),
  on(AuthActions.brandListSuccess, (state, { brandList }): AuthState => {
    return { ...state, brandList };
  }),
  on(AuthActions.brandListFailure, (state): AuthState => {
    return { ...state, brandList: [] };
  }),
  on(AuthActions.selectedBrand, (state, { brand }): AuthState => {
    return { ...state, brand };
  }),
  on(AuthActions.brandSelect, (state, { brand }): AuthState => {
    return { ...state, brand };
  }),
  on(AuthActions.menuExpand, (state): AuthState => {
    return { ...state, isMenuExpanded: true };
  }),
  on(AuthActions.menuCollapse, (state): AuthState => {
    return { ...state, isMenuExpanded: false };
  }),
  on(AuthActions.systemFilterSearch, (state, { searchString }): AuthState => {
    return { ...state, systemFilterSearch: searchString };
  }),
);

export const getToken = (state: AuthState) => state.tokenInfo;

export const getUser = (state: AuthState) => state.user;

export const getBrand = (state: AuthState) => state.brand;

export const getCollections = (state: AuthState) => state.collections;

export const getBrandList = (state: AuthState) => state.brandList;

export const getMenuExpanded = (state: AuthState) => state.isMenuExpanded;
