import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notAvailable',
  standalone: true,
})
export class NotAvailablePipe implements PipeTransform {
  transform(value: string | undefined | null): string {
    if (!value) {
      return 'N/A';
    }

    return value;
  }
}
