import { Injectable } from '@angular/core';
import { RktIcon } from './rkt-icons';

@Injectable({
  providedIn: 'root',
})
export class RktIconRegistryService {
  private registry = new Map<string, string>();

  public registerIcons(icons: RktIcon[]): void {
    icons.forEach((icon: RktIcon) => this.registry.set(icon.name, icon.data));
  }

  public getIcon(iconName: string): string | undefined {
    if (!this.registry.has(iconName)) {
      console.warn(`The ${iconName} Icon was not found, is it in the Icon registry?`);
    }
    return this.registry.get(iconName);
  }
}
