<div class="main-nav" [class.main-nav-expanded]="isMenuExpanded$ | async">
  <a [routerLink]="['/business-accounts']" routerLinkActive="active" class="logo" title="RocketCOMS"></a>

  <div class="main-nav-items">
    <div class="main-nav-item" *ngFor="let menuItem of menuItems">
      <a class="nav-item-link" [routerLink]="menuItem.link" routerLinkActive="active">
        <rkt-icon [name]="menuItem.icon"></rkt-icon>
        <span class="link-text">{{ menuItem.text }}</span>
      </a>
    </div>
  </div>

  <div class="user-menu">
    <button type="button" class="control-wrapper user" (click)="dropdownMenu.toggle($event)">
      <div class="user-avatar" *ngIf="activeUser$ | async as user">{{ user?.firstName?.charAt(0) }}</div>

      <div class="user-name" *ngIf="activeUser$ | async as user">{{ user?.firstName }} {{ user?.lastName }}</div>
      <rkt-menu
        [className]="(isMenuExpanded$ | async) ? 'user-dropdown expanded' : 'user-dropdown'"
        #dropdownMenu
        [model]="dropdownMenuItems"
        [width]="220"
      ></rkt-menu>
    </button>
  </div>
</div>
