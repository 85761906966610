import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isEmpty, isNull } from 'lodash-es';
import { isValidNumberForRegion, ParseError, parsePhoneNumber } from 'libphonenumber-js';
import { detectCountryFromPhoneNumber } from '../utils';
import { FormlyFieldConfig } from '@ngx-formly/core';

export const phoneNumberValidator = (control: AbstractControl, field: FormlyFieldConfig): ValidationErrors | null => {
  const { countryRestrictions, restrictedCountryMessage } = field?.props ?? {};
  const phoneNumber = control.value;
  let message = '';

  if (!phoneNumber || isNull(phoneNumber) || phoneNumber.length < 8) {
    return null;
  }

  try {
    const inputCountry = detectCountryFromPhoneNumber(phoneNumber)?.country;
    const parsedNumber = parsePhoneNumber(phoneNumber, inputCountry);

    if (
      !parsedNumber.isValid() ||
      !parsedNumber.isPossible() ||
      (inputCountry && !isValidNumberForRegion(parsedNumber.nationalNumber, inputCountry))
    ) {
      message = 'Please enter a valid phone number.';
    }

    if (countryRestrictions?.length && !countryRestrictions.includes(inputCountry)) {
      message = restrictedCountryMessage ?? 'The phone number of this country is not supported.';
    }
  } catch (error) {
    if (error instanceof ParseError) {
      switch (error.message) {
        case 'TOO_SHORT':
          message = 'The phone number is too short for this country code.';
          break;
        case 'TOO_LONG':
          message = 'The phone number is too long for this country code.';
          break;
        case 'INVALID_LENGTH':
          message = 'Invalid phone number length.';
          break;
        case 'INVALID_COUNTRY':
          message = 'Invalid phone number for this country.';
          break;
        case 'NOT_A_NUMBER':
          message = 'Please enter a valid phone number.';
          break;
        default:
          break;
      }
    }
  }

  return isEmpty(message) ? null : { isValidPhoneNumber: { message } };
};
