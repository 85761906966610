import { Pipe, PipeTransform } from '@angular/core';
import { DateFnsInputDate } from '@rocketcoms/interfaces';
import { format } from 'date-fns';

export const isValidTableDate = (date: DateFnsInputDate): date is Date => !(date === undefined || date === null || !date);

@Pipe({
  name: 'rktDateFormat',
  standalone: true,
})
export class DateFormatPipe implements PipeTransform {
  transform(date: DateFnsInputDate, dateFormat: string): string {
    if (date && isValidTableDate(new Date(date))) {
      return format(new Date(date), dateFormat);
    }
    return '';
  }
}
