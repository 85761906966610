/// <reference types="@types/google.maps" />
import { AfterViewInit, Component, ElementRef, HostBinding, ViewChild } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { compact } from 'lodash-es';
import { InputPlaceProps } from '../form.types';
import { getCountryDetails } from '../utils';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'rkt-form-input-place',
  template: `
    <input
      #inputRef
      class="rkt-form-input"
      [name]="fieldName"
      [placeholder]="to.placeholder"
      [formlyAttributes]="field"
      [class.is-invalid]="showError"
      [formControl]="formControl"
    />
  `,
  standalone: true,
  imports: [ReactiveFormsModule, FormlyModule],
})
export class RktFormInputPlaceComponent extends FieldType<FieldTypeConfig<InputPlaceProps>> implements AfterViewInit {
  @HostBinding('class.rkt-form-input-field') commonClass = true;

  @ViewChild('inputRef') inputRef?: ElementRef;

  autocomplete!: google.maps.places.Autocomplete;

  get fieldName() {
    return this.field.name ?? (this.props.label?.toLowerCase().replace(/\s/g, '-') || '');
  }

  ngAfterViewInit(): void {
    this.initGoogleMapsService();
  }

  private initGoogleMapsService(): void {
    this.autocomplete = new google.maps.places.Autocomplete(this.inputRef?.nativeElement, {
      componentRestrictions: { country: this.props.countryRestrictions || [] },
      fields: ['address_components'],
      types: ['address'],
    });

    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace().address_components as google.maps.GeocoderAddressComponent[];
      this.setGoogleAddressResponse(place);
    });
  }

  private setGoogleAddressResponse(address: google.maps.GeocoderAddressComponent[]): void {
    const countryName = address.find((comp) => comp.types[0] === 'country')?.short_name;
    const streetNumber = address.find((comp) => comp.types[0] === 'street_number')?.short_name;
    const streetName = address.find((comp) => comp.types[0] === 'route')?.long_name;
    const subPremise = address.find((comp) => comp.types[0] === 'subpremise')?.long_name;
    const country = countryName ? getCountryDetails(countryName)?.iso3 : countryName;
    const city = address.find((comp) => comp.types.find((type) => type === 'locality'))?.short_name;
    const subLocality = address.find((comp) => comp.types.find((type) => type === 'sublocality'))?.short_name;
    const state = address.find((comp) => comp.types[0] === 'administrative_area_level_1')?.short_name;
    const postalCode = address.find((comp) => comp.types[0] === 'postal_code')?.short_name;
    const postalCodeSuffix = address.find((comp) => comp.types[0] === 'postal_code_suffix')?.short_name;

    this.form.get('addressLine1')?.setValue(compact([streetNumber, streetName]).join(' '));
    this.form.get('addressLine2')?.setValue(subPremise);
    this.form.get('city')?.setValue(compact([city, subLocality]).join(' '));
    this.form.get('state')?.setValue(state);
    this.form.get('postalCode')?.setValue(compact([postalCode, postalCodeSuffix]).join('-'));
    this.form.get('country')?.setValue(country);
  }
}
