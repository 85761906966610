import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthActions } from '../..';
import { NotificationService } from '@rocketcoms/data-access';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private store: Store,
    private notificationService: NotificationService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        const error = [504, 502].includes(errorResponse.status) ? errorResponse : { ...errorResponse.error };

        switch (error.status) {
          case '0017':
            error.message = `Oops! The information entered was incorrect. Please check and try again.\n${error.message}`;
            break;
          case '0026':
            error.message = 'User not found.';
            break;
          case '0032':
            error.message = 'Invalid email or password.';
            break;
          case '0033':
            error.message = 'Your account has a maximum number of users.';
            break;
          default:
            error.message = error.message ?? 'We are unable to complete this request. Please contact your account administrator.';
            console.error(error.status, error);
            break;
        }

        if (error.status === '0422' && request.url.includes('refresh')) {
          this.notificationService.info({ message: 'Session Expired. Please login again to resume.' }, { timeOut: 60000 });
          this.store.dispatch(AuthActions.logout({ params: { isReturnUrl: true } }));
        }

        if (error.status === '0403' || error.status === '403' || errorResponse.status === HttpStatusCode.Forbidden) {
          this.notificationService.info({ message: 'Session Expired. Please login again to resume.' }, { timeOut: 60000 });
          this.store.dispatch(AuthActions.logout({ params: { isReturnUrl: true } }));
        }

        return throwError(() => error);
      }),
    );
  }
}
