import { Component, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { FieldGroupTypeConfig, FieldType, FormlyModule } from '@ngx-formly/core';
import { NgFor } from '@angular/common';

@Component({
  selector: 'formly-group', // eslint-disable-line @angular-eslint/component-selector
  template: `
    <formly-field *ngFor="let f of field.fieldGroup" [field]="f"></formly-field>
    <ng-content></ng-content>
  `,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class]': 'field.fieldGroupClassName || ""',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, FormlyModule],
})
export class RktFormlyFieldGroupComponent extends FieldType<FieldGroupTypeConfig> {
  @HostBinding('class.rkt-form-field-group') commonClass = true;
}
