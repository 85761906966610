import { NgClass, NgIf, NgStyle } from '@angular/common';
import {
  Component,
  Input,
  forwardRef,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  OnInit,
  Provider,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

interface ChangedModel {
  originalEvent: Event;
  checked: boolean;
}

export const INPUTSWITCH_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputSwitchComponent),
  multi: true,
};

@Component({
  selector: 'input-switch',
  templateUrl: './input-switch.component.html',
  styleUrls: ['./input-switch.component.scss'],
  providers: [INPUTSWITCH_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgClass, NgStyle],
})
export class InputSwitchComponent implements ControlValueAccessor, OnInit {
  @ViewChild('rbnkInputSwitch', { read: ElementRef, static: false })
  elementRef!: ElementRef;

  @Input() style!: { [klass: string]: unknown }[];

  @Input() styleClass!: string;

  @Input() tabindex!: number;

  @Input() inputId!: string;

  @Input() name!: string;

  @Input() disabled!: boolean;

  @Input() readonly!: boolean;

  @Input() ariaLabelledBy!: string;

  @Input() initialValue!: string;

  @Input() isCustomTheme = false;

  @Output() changed: EventEmitter<ChangedModel> = new EventEmitter();

  @Output() modelChanged: EventEmitter<ChangedModel> = new EventEmitter();

  @Output() modelTouched: EventEmitter<ChangedModel> = new EventEmitter();

  checked = false;

  focused = false;

  constructor(private ref: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.initialValue === 'true') {
      this.writeValue(true);
    }
  }

  onModelChange: (checked?: boolean) => void = () => {
    this.modelChanged.emit();
  };

  onModelTouched: () => void = () => {
    this.modelTouched.emit();
  };

  onClick(event: Event): void {
    if (!this.disabled && !this.readonly) {
      event.preventDefault();
      this.toggle(event);
    }
  }

  onInputChange(event: Event): void {
    if (!this.readonly) {
      const inputChecked = (event.target as HTMLInputElement).checked;
      this.updateModel(event, inputChecked);
    }
  }

  toggle(event: Event): void {
    this.updateModel(event, !this.checked);
    this.elementRef.nativeElement.blur();
  }

  updateModel(event: Event, value: boolean): void {
    this.checked = value;
    this.onModelChange(this.checked);
    this.changed.emit({
      originalEvent: event,
      checked: this.checked,
    });
  }

  onFocus(): void {
    this.focused = true;
  }

  onBlur(): void {
    this.focused = false;
    this.onModelTouched();
  }

  writeValue(checked: boolean): void {
    this.checked = checked;
    this.ref.markForCheck();
  }

  registerOnChange(fn: () => void): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onModelTouched = fn;
  }

  setDisabledState(val: boolean): void {
    this.disabled = val;
    this.ref.markForCheck();
  }
}
