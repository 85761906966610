import { Component, Input } from '@angular/core';
import { RktIconComponent } from '../../icon';
import { NgIf } from '@angular/common';

@Component({
  selector: 'rkt-form-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  standalone: true,
  imports: [NgIf, RktIconComponent],
})
export class RktFormTooltipComponent {
  @Input() tooltip?: string | null;

  isTooltipDisplayed = false;

  showTooltip(): void {
    if (this.isTooltipDisplayed) {
      return;
    }

    this.isTooltipDisplayed = true;
  }

  hideTooltip(): void {
    if (!this.isTooltipDisplayed) {
      return;
    }

    this.isTooltipDisplayed = false;
  }
}
