<div class="auth-background"></div>
<div class="auth-layout">
  <div class="auth-layout-content">
    <div class="left-col" *ngIf="!noCols">
      <div class="onboarding">
        <a href="/" class="logo"></a>

        <a href="/" class="alt-logo"></a>

        <div class="auth-content-message">
          <ng-container
            *ngTemplateOutlet="
              authMessages;
              context: {
                $implicit: skipQuotes ? quotes.length : templateNumber,
              }
            "
          ></ng-container>
        </div>
      </div>
    </div>
    <div class="right-col">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="footer-section">
    <div class="footer-links">
      <div class="footer-link-wrapper">
        <button appRemoveFocus class="footer-link" (click)="onPrivacyPolicyClick()" title="Privacy Policy">Privacy Policy</button>
      </div>

      <div class="footer-link-wrapper">
        <button appRemoveFocus class="footer-link" (click)="onTermsConditionsClick()" title="Terms & Conditions">Terms</button>
      </div>

      <div class="footer-link-wrapper">
        <a class="footer-link" href="https://developer.rocketcoms.com" rel="noopener" target="_blank" title="API Documentation"
          >API Documentation</a
        >
      </div>
    </div>

    &copy; {{ currentYear }} RocketCOMS, Inc. All rights reserved.
  </div>
</div>

<ng-template #authMessages let-templateNumber>
  <ng-container *ngIf="templateNumber >= quotes.length; else quoteTemplate">
    <div class="onboarding-title">
      <span>Plan</span> your<br />
      activities and<br />
      <span>control</span> your<br />
      progress online
    </div>
  </ng-container>

  <ng-template #quoteTemplate>
    <ng-container *ngIf="quotes[templateNumber].author; else noAuthorTemplate">
      &ldquo;{{ quotes[templateNumber].text }}&rdquo;
      <i class="quote-author">{{ quotes[templateNumber].author }}</i>
    </ng-container>

    <ng-template #noAuthorTemplate>
      {{ quotes[templateNumber].text }}
    </ng-template>
  </ng-template>
</ng-template>
