<div
  class="body-cell"
  [ngClass]="[
    column.prop ? 'body-cell-' + column.prop : '',
    column.className ? 'body-cell-' + column.className : '',
    'body-cell-align-' + (column.colAlign || 'left'),
    column.isMultiline ? 'body-cell-multiline' : '',
  ]"
  [ngStyle]="{ 'height.px': rowHeight }"
>
  <div class="body-cell-inner" [ngClass]="column.colType ? 'body-cell-inner-' + column.colType : ''">
    <div class="body-cell-value" *ngIf="column.colType === 'long-id'" [title]="value | uppercase">
      {{ value | uppercase }}
    </div>

    <div class="body-cell-value" *ngIf="column.colType === 'short-id'" [title]="value | uppercase">
      {{ value | rktShortId }}
    </div>

    <div
      class="body-cell-value"
      [class.body-cell-value-empty]="!value"
      [class.no-value]="isEmptyValue"
      *ngIf="column.colType === 'amount'"
      [title]="'$ ' + (value | number: amountFormat)"
    >
      {{ !isEmptyValue ? '$ ' + (value | number: amountFormat) : emptyValuePlaceholder }}
    </div>

    <div
      class="body-cell-value status-tag"
      *ngIf="column.colType === 'status-tag'"
      [ngClass]="'status-tag-' + value.toLowerCase()"
      [class.no-value]="isEmptyValue"
      [title]="value | titlecase"
    >
      {{ !isEmptyValue ? (formatStatusTag(value) | titlecase) : emptyValuePlaceholder }}
    </div>

    <div
      class="body-cell-value"
      *ngIf="column.colType === 'date-time'"
      [title]="value ? (value | rktDateFormat: dateTimeFormat) : ''"
      [class.no-value]="isEmptyValue"
    >
      {{ (value | rktDateFormat: dateTimeFormat) || emptyValuePlaceholder }}
    </div>

    <div
      class="body-cell-value"
      *ngIf="column.colType === 'input-switch'"
      [title]="value ? (value | rktDateFormat: dateTimeFormat) : ''"
      [class.no-value]="isEmptyValue"
    >
      <input-switch [ngModel]="value" [initialValue]="value" (changed)="onSwitchToggle($event, column.prop)"></input-switch>
    </div>

    <div
      class="body-cell-value"
      *ngIf="column.colType === 'date'"
      [title]="value ? (value | rktDateFormat: dateFormat) : ''"
      [class.no-value]="isEmptyValue"
    >
      {{ (value | rktDateFormat: dateFormat) || emptyValuePlaceholder }}
    </div>

    <div
      class="body-cell-value"
      *ngIf="column.colType === 'time'"
      [title]="value ? (value | rktTimeFormat: timeFormat) : ''"
      [class.no-value]="isEmptyValue"
    >
      {{ (value | rktTimeFormat: timeFormat) || emptyValuePlaceholder }}
    </div>

    <div
      class="body-cell-value"
      *ngIf="column.colType === 'id-date-time-combined'"
      [title]="
        'ID: ' +
        (value | rktSplit: '::' : 0 | uppercase) +
        '; Created Date: ' +
        (value | rktSplit: '::' : 1 | rktDateFormat: dateTimeFormat)
      "
    >
      <div class="combined-value-id">
        {{ value | rktSplit: '::' : 0 | rktShortId }}
      </div>
      <div class="combined-value-date-time">
        {{ value | rktSplit: '::' : 1 | rktDateFormat: dateTimeFormat }}
      </div>
    </div>

    <div
      class="body-cell-value"
      *ngIf="column.colType === 'two-line-col'"
      [title]="value ? value.replace('::', ' ') : emptyValuePlaceholder"
    >
      <div class="two-line-col-item two-line-col-item-first" [class.no-value]="!(value | rktSplit: '::' : 0)">
        {{ (value | rktSplit: '::' : 0) || emptyValuePlaceholder }}
      </div>
      <div class="two-line-col-item two-line-col-item-second" [class.no-value]="!(value | rktSplit: '::' : 1)">
        {{ (value | rktSplit: '::' : 1) || emptyValuePlaceholder }}
      </div>
    </div>

    <div class="body-cell-button" *ngIf="column.colType === 'icon-button'">
      <button
        type="button"
        [class.disabled]="!value"
        [disabled]="!value"
        (click)="onBtnClick($event, value)"
        class="icon-button-cell"
        [title]="column.colTitle || ''"
      >
        <rkt-icon [name]="column.iconName!"></rkt-icon>
      </button>
    </div>

    @if (column.colType === 'two-state-button') {
      <div class="body-cell-button">
        @for (state of column.states; track state.value) {
          @if (state.value === value) {
            <button
              type="button"
              (click)="onBtnClick($event, state.action)"
              [className]="'two-button-cell two-button-cell-' + state.action"
              [title]="state.title"
            >
              <rkt-icon [name]="state.iconName!"></rkt-icon>
            </button>
          }
        }
      </div>
    }

    <div class="body-cell-button" *ngIf="column.colType === 'icon'">
      <rkt-icon [name]="value" *ngIf="value"></rkt-icon>
      <ng-container *ngIf="!value">{{ emptyValuePlaceholder }}</ng-container>
    </div>

    <div class="body-cell-button" *ngIf="column.colType === 'button'">
      <button
        type="button"
        [class.disabled]="!value"
        [disabled]="!value"
        (click)="onBtnClick($event, value)"
        class="button-cell"
        [title]="column.colTitle || ''"
      >
        <rkt-icon [name]="column.iconName!"></rkt-icon>
        {{ column.colTitle }}
      </button>
    </div>

    <div class="body-cell-value" *ngIf="column.colType === 'tooltip'">
      @if (value && column.tooltipText) {
        <rkt-icon class="body-cell-tooltip-icon" name="info" [rktTooltip]="column.tooltipText"></rkt-icon>
      }
    </div>

    <div class="body-cell-value" *ngIf="!column.colType || column.colType === 'text'" [title]="value" [class.no-value]="isEmptyValue">
      {{ !isEmptyValue ? value : emptyValuePlaceholder }}
    </div>
  </div>
</div>
