import { Directive, HostBinding, Input } from '@angular/core';
import { ButtonType } from './button.types';

@Directive({
  selector: 'button[rktButton]',
  standalone: true,
})
export class RktButtonDirective {
  @Input() btnType: ButtonType = 'primary';

  @Input() disabled = false;

  @HostBinding('class') get classes(): Record<string, boolean> {
    return {
      'rkt-button': true,
      [`rkt-button-${this.btnType}`]: true,
      disabled: this.disabled,
    };
  }

  @HostBinding('attr.disabled') get attrDisabled() {
    return this.disabled || null;
  }
}
