import { Component, HostBinding, Input } from '@angular/core';
import { PaginationControlsComponent, NgxPaginationModule } from 'ngx-pagination';
import { RktIconComponent } from '../../icon';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
  selector: 'rkt-table-pager',
  templateUrl: './pager.component.html',
  standalone: true,
  imports: [NgxPaginationModule, NgIf, RktIconComponent, NgFor, NgClass],
})
export class RktTablePagerComponent extends PaginationControlsComponent {
  @HostBinding('class.rkt-table-pager') pager = true;

  @Input() limit!: number;

  @Input() paginationLabel?: string;
}
