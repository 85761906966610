import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { PrivacyPolicyComponent, TermsConditionsComponent } from '..';
import { ModalService } from '@rocketcoms/rocket-ui';
import { FocusRemoveDirective } from '@rocketcoms/utils';
import { getYear } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

import { random } from 'lodash-es';

@Component({
  selector: 'app-auth-layout',
  templateUrl: 'auth-layout.component.html',
  styleUrls: ['auth-layout.component.scss'],
  standalone: true,
  imports: [CommonModule, NgTemplateOutlet, FocusRemoveDirective],
})
export class AuthLayoutComponent {
  get currentYear(): string {
    return `${getYear(toZonedTime(new Date(), 'America/Toronto'))}`;
  }

  constructor(private modalService: ModalService) {}

  onPrivacyPolicyClick() {
    this.modalService.open(PrivacyPolicyComponent, {
      className: 'auth-document-modal',
    });
  }

  onTermsConditionsClick() {
    this.modalService.open(TermsConditionsComponent, {
      className: 'auth-document-modal',
    });
  }

  @Input() noCols = false;

  @Input() skipQuotes = false;

  quotes: { text: string; author?: string }[] = [
    {
      text: 'The path from dreams to success does exist. May you have the vision to find it, the courage to get on to it, and the perseverance to follow it.',
      author: 'Kalpana Chawla',
    },
    {
      text: "I believe every human has a finite number of heartbeats. I don't intend to waste any of mine.",
      author: 'Neil Armstrong',
    },
    {
      text: 'Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful.',
      author: 'Albert Schweitzer',
    },
    {
      text: 'There is still no cure for the common birthday.',
      author: 'John Glenn',
    },
    {
      text: 'We came all this way to explore the moon, and the most important thing is that we discovered the Earth.',
      author: 'William Anders',
    },
    {
      text: 'Exploration is really the essence of the human spirit.',
      author: 'Frank Borman',
    },
    {
      text: 'The greatest enemy of progress is the illusion of knowledge.',
      author: 'John Young',
    },
    {
      text: "Mystery creates wonder and wonder is the basis of man's desire to understand.",
      author: 'Neil Armstrong',
    },
    {
      text: "I still say, 'Shoot for the moon; you might get there.'",
      author: 'Buzz Aldrin',
    },
    {
      text: "Did you know it's illegal to feed pigeons on the sidewalks and streets in San Francisco?",
    },
    {
      text: 'Did you know the official bird of Redondo Beach, California, is the Goodyear Blimp?',
    },
    {
      text: 'Did you know "Q" is the only letter that doesn\'t appear in any U.S. state name?',
    },
    {
      text: 'Did you know it would cost $18.3 million to make a replica Darth Vader suit?',
    },
    {
      text: 'Did you know the filling in Kit Kats is made from damaged Kit Kats?',
    },
    { text: 'Did you know the youngest Olympian was 10 years old?' },
    { text: 'Did you know there are 118 ridges on the side of a dime?' },
    { text: "Did you know there's a planet that's shaped like a potato?" },
    { text: "Did you know there's a 3,400-year-old song?" },
    { text: 'Did you know the Statue of Liberty wears a size 879 shoe?' },
    {
      text: 'Did you know the original Back to the Future time machine was a fridge?',
    },
    {
      text: "Did you know there's a precise speed where jogging becomes running?",
    },
    {
      text: "Did you know Santa Claus was given an official pilot's license in 1927?",
    },
    {
      text: 'Did you know William Shakespeare invented more than 1,700 words?',
    },
    {
      text: 'Did you know the first item sold on eBay was a broken laser pointer?',
    },
    { text: "Animals that lay eggs don't have belly buttons." },
    { text: 'There is a McDonalds in every continent except Antarctica.' },
    { text: 'Mosquitos are attracted to people who just ate bananas.' },
    { text: "Sonic the Hedgehog's full name is Ogilvie Maurice Hedgehog." },
    { text: "The world's termites outweigh the world's humans about 10 to 1." },
    { text: 'Most toilet paper sold in France is pink.' },
    { text: 'The Hawaiian alphabet only has 12 letters.' },
    { text: 'The human nose can remember 50,000 different scents.' },
    { text: 'Children tend to grow faster in the spring.' },
    {
      text: 'The television was invented two years after the invention of sliced bread.',
    },
    {
      text: 'If you keep a goldfish in a dark room, it will eventually turn white.',
    },
    {
      text: "It took the creator of the Rubik's Cube one month to solve the cube after he created it.",
    },
    {
      text: "Japanese square watermelons aren't edible. They are purely ornamental!",
    },
    { text: "Elephants are the only mammal that can't jump." },
    {
      text: 'Alligators will give manatees the right of way if they are swimming near each other.',
    },
  ];

  templateNumber = random(this.quotes.length);
}
