<button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>

<h1 class="title">Privacy Policy</h1>

<div class="content">
  <ngx-extended-pdf-viewer
    [src]="url"
    [showBorders]="false"
    [showToolbar]="false"
    [textLayer]="false"
    [sidebarVisible]="false"
    backgroundColor="#F7F7F7"
    [authorization]="true"
  ></ngx-extended-pdf-viewer>
</div>

<div class="buttons">
  <button rktButton btnType="tertiary" (click)="downloadPrivacyPolicy()">Download PDF</button>
  <button rktButton (click)="activeModal.close()">Close</button>
</div>
